<template>
    <div class="card">
      <div class="card-header bg-navyblue">
        <strong>Rental Information</strong>
      </div>
      <div class="card-block bg-white">
        <div class="form-group row">
          <label class="col-sm-4 col-4 col-form-label px-0">Rental Agreement Number</label>
          <div class="col-sm-8 col-8">
            <input type="text" v-model="rentalsNumbers" class="form-control" readonly>
          </div>
        </div>
        <div class="form-group row">
          <label for="owner" class="col-sm-4 col-4 col-form-label px-0">Rental Total</label>
          <div class="col-sm-8 col-8">
            <number-formatter
                v-if="rentalsNumbers != ''"
                v-model="rentalTotal"
                type="text"
                class="form-control numeric"
                format="$0,0.00"
                readonly
            ></number-formatter>
            <number-formatter
                v-else
                v-model="settled.rentalTotalAmount"
                type="text"
                class="form-control numeric"
                format="$0,0.00"
            ></number-formatter>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import NumberFormatter from "@/components/utility/number-formatter";

export default {
  name: "block-rental-information",
  components: {
    NumberFormatter,
  },
  props: {
    rentals: Array,
    rentalTotal: {
      type: Number,
      default(){
        return 0;
      }
    },
    vehicleOwner: {
      type: String,
      default(){
        return '';
      }
    },
    settled: {
      type: Object,
      default: function(){
        return {
          repairSettledAmount: null,
          repairSettledDate: null,
          repairLegalFees: null,
          rentalTotalAmount: null,
          rentalSettledAmount: null,
          rentalSettledDate: null,
          rentalLegalFees: null,
          injuryTotalAmount: null,
          injurySettledAmount: null,
          injurySettledDate: null,
          injuryLegalFees: null,
          assessmentFeeTotalAmount: null,
          assessmentFeeSettledAmount: null,
          assessmentFeeLegalFees: null,
        };
      },
    },
  },
  computed: {
    rentalsNumbers(){
      let numbers = [];
      for(let rental of this.rentals){
        if(rental.details.hirerName != this.vehicleOwner) continue;
        numbers.push(rental.details.rentalAgreementNumber)
      }
      return numbers.join('; ');
    }
  },
}
</script>

<style scoped>

</style>
