<template>
    <div>
        <span @click="show = !show" class="text-grey" :class="{'display-none': isNew}" style="position: absolute; top: 18px; transform:translateY(-50%); right: 1.5rem;">
            <i class="bx" :class="{'bxs-chevron-down': !show, 'bxs-chevron-up': show}"></i>
        </span>
        <span :class="{'display-none': !isNew, 'text-grey': true}" style="position:absolute;top: 50%; transform:translateY(-50%);right:2rem;font-size: 10px;">NEW</span>
        <input ref="asb" type="text" v-model="asbValue" class="form-control" @keypress="keyPress" @keydown="keyDown" @keydown.down.prevent="keyDownPrevent" @keydown.up.prevent="keyUp"
               @keyup.enter.prevent="keyEnter"
               @keyup.esc.prevent="onEscape" @blur="onBlur" @focus="onFocus" :disabled="isDisabled" :placeholder="placeholder" :id="id" @change="onChange">
        <div v-if="show" :class="{'search-box-container': true}">
            <div class="search-box">
                <div class="search-results">
                  <ul v-if="isShowAddUpdate" class="sr-options" @mouseenter="mouseEnter" @mouseleave="mouseLeave">
                    <li :class="{'selected': index == currentPosition, 'sr-option': true}" v-for="(sr, index) in searchResult" v-bind:key="index" @mouseover="mouseOver(index)" @click="keyEnter"
                        v-html="sr.replace(regTerm, '<b>$&</b>')"></li>
                    <template>
                      <li :class="{'selected': searchResultCount == currentPosition, 'sr-option': true}" v-if="!searchResultCount && searchLabel && asbValue && asbValueExists === -1">
                        No {{searchLabel.toLowerCase()}} found
                      </li>
                      <li :class="{'selected': searchResultCount == currentPosition, 'sr-option': true}" v-if="searchLabel && asbValue && asbValueExists === -1"
                          @mouseover="mouseOver(searchResultCount)" @click="keyEnter"><strong>Add</strong> {{asbValue}} as a new {{searchLabel.toLowerCase()}}</li>
                      <li :class="{'selected': searchResultCount == currentPosition, 'sr-option': true}" v-if="searchLabel && asbValue && asbValueExists === -1">
                        Or
                      </li>
                      <li :class="{'selected': searchResultCount == currentPosition, 'sr-option': true}" v-if="searchLabel && asbValue && asbValueExists === -1"
                          @mouseover="mouseOver(searchResultCount)" @click="onUpdate(asbValue)"><strong>Update</strong> {{searchLabel.toLowerCase()}} as {{asbValue}}</li>
                    </template>
                  </ul>
                    <ul v-else-if="searchKey" class="sr-options" @mouseenter="mouseEnter" @mouseleave="mouseLeave">
                        <li :class="{'selected': index == currentPosition, 'sr-option': true}" v-for="(sr, index) in searchResult" v-bind:key="index" @mouseover="mouseOver(index)" @click="keyEnter"
                            v-html="sr[key].replace(regTerm, '<b>$&</b>')"></li>
                        <template>
                            <li :class="{'selected': searchResultCount == currentPosition, 'sr-option': true}" v-if="!searchResultCount && searchLabel && asbValue && asbValueExists === -1">
                                No {{searchLabel.toLowerCase()}} found
                            </li>
                            <li :class="{'selected': searchResultCount == currentPosition, 'sr-option': true}" v-if="searchLabel && asbValue && asbValueExists === -1"
                                @mouseover="mouseOver(searchResultCount)" @click="keyEnter"><strong>Add</strong> {{asbValue}} as a new {{searchLabel.toLowerCase()}}</li>
                            <li :class="{'selected': searchResultCount == currentPosition, 'sr-option': true}" v-if="searchLabel && asbValue && asbValueExists === -1">
                                Or
                            </li>
                            <li :class="{'selected': searchResultCount == currentPosition, 'sr-option': true}" v-if="searchLabel && asbValue && asbValueExists === -1"
                                @mouseover="mouseOver(searchResultCount)" @click="onUpdate(asbValue)"><strong>Update</strong> {{searchLabel.toLowerCase()}} as {{asbValue}}</li>
                        </template>
                    </ul>
                    <ul v-else class="sr-options" @mouseenter="mouseEnter" @mouseleave="mouseLeave">
                        <li :class="{'selected': index == currentPosition, 'sr-option': true}" v-for="(sr, index) in searchResult" v-bind:key="index" @mouseover="mouseOver(index)" @click="keyEnter"
                            v-html="sr.replace(regTerm, '<b>$&</b>')"></li>
                        <li :class="{'selected': searchResultCount == currentPosition, 'sr-option': true}" v-if="searchLabel && asbValue && asbValueExists === -1"
                            @mouseover="mouseOver(searchResultCount)" @click="keyEnter">+ New {{searchLabel}}<br/><strong>{{asbValue}}</strong></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import advancedSearch from './advanced-search';

    export default {
        name: 'advancedSearchWithNew',
        extends: advancedSearch,
        methods: {
            onUpdate(val) {
                this.show = false;
                this.$emit('onUpdate', val);
            }
        },
      props:{
        isShowAddUpdate:{
          type: Boolean,
          default: false,
        }
      }
    };
</script>
