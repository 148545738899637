<template>
  <div class="order-progress">
    <div class="all">
      <div class="header d-flex align-self-center">
        <div class="header-name mr-auto align-self-center">{{ title }}</div>
        <div v-if="link" class="header-link mr-auto align-self-right nowrap">
            <a v-if="link.isGoToActivityRef" :href="link.href" @click="goToActivityLink">{{ link.text }}</a>
            <a v-else :href="link.href">{{ link.text }}</a>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-12 col-lg-12">
          <div class="steps">
            <ul class="wizard__steps">
              <li class="wizard__step"
                  v-for="(step, index) of showingSteps" :key="index"
                  :class="{
                   'active':  currentOrder >= Number(step.order),
                   'current': currentOrder === Number(step.order),
                  }"
                  :style="stepStyle">

                  <span class="wizard__step__line"></span>
                  <span class="wizard__step__indicator"><i class='bx bx-check'></i></span>
                  <span class="wizard__step__label">
                          <div>{{ step.label }}</div>
                  </span>
                  <a v-if="currentOrder != Number(step.order)" @click="stepClicked(step)" class="link-to-change-status"><span></span></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import _ from "lodash";

    export default {
  name: 'progress-bar',
  components: {},
  props: {
    link: {
        type: [Object, null],
      default: () => null
    },
    showingCount: {
      type: Number,
      default: 4
    },
    title: {
      type: String,
      default: "Progress bar"
    },
    steps: {
      type: Array,
      default: () => [
      ]
    },
    currentStep: {
      type: Number,
      default: 1
    },
  },
  data(){
    return {};
  },
  computed: {
    stepStyle() {
      return {
        width: `${ 100 / this.showingSteps.length }%`,
      };
    },
    currentOrder() {
      if (this.currentStatus) {
        return Number(this.currentStatus.order);
      }
      return -1;
    },
    currentStatus() {
      let s = this.currentStep;
      return _.find(this.showingSteps, function (o) {
        return Number(o.id) === Number(s);
      });
    },
    showingSteps(){
      // sort by `order` field
      if (!(this.steps.length > 0)) {
        return [];
      }
      let allSteps = _.sortBy(this.steps, ['order']);
      // getting index of current step
      let pos = _.findIndex(allSteps, el => el.id == this.currentStep);

      let steps = [];

      // starting from previous step before current
      if(pos > 0){
        pos--;
      }

      // filling steps array to display
      for(let i = 0, p = pos; i < this.showingCount && p < allSteps.length; i++, p++){
        steps.push(allSteps[p]);
      }

      // if current step close to the end of statuses list
      // prepend previous steps to match showing count
      while(steps.length < this.showingCount){
        if(pos == 0) break;
        pos--;
        steps.unshift(allSteps[pos]);
      }

      return steps;
    }
  },
  methods: {
      goToActivityLink() {
          this.$emit('on-click-go-to-full-activity');
      },
    stepClicked(step){
      console.log('step',step);
      //this.currentStep = step.id;
      this.$emit('on-step-changed', step);
    },
  },
}
</script>
<style scoped>
    .link-to-change-status {
        position: absolute;
        width: 100%;
        height: 70px;
        left: 0;
        z-index: 100;
        cursor: pointer;
    }
.order-progress .all {
  min-height: 113px;
  margin: 15px;
}

.order-progress .like span {
  vertical-align: middle;
  font-size: 13px;
}
.order-progress .like i.theme-color{
  cursor: default !important;
}

.order-progress .like i{
  cursor: pointer !important;
}

.order-progress .like {
  font-size: 24px;
  margin: 0 0 0 0;
  min-width: 200px;
  z-index: 99999;
}

.order-progress .header {
  margin-bottom: 10px;
  overflow: initial !important;
}

.order-progress .header .header-name {
  color: #1C1F39;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  width: 100%;
}

.order-progress .header .header-link a {
  color: #00b050;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 24px;
}

.order-progress .header .timer {
  color: #1C1F39;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  margin-left: 15px;
}
.nowrap {
  white-space: nowrap;
}

</style>
