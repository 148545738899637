<template>

  <div class="card">
    <div v-if="!hideHeader" class="card-header bg-navyblue">
      <strong>Settlement</strong>
    </div>
    <div class="card-block bg-white">

      <div class="row mb-2">
        <div class="col"><h5>Repairs</h5></div>
      </div>
      <div class="form-group row">
        <label class="col-sm-6 col-6 col-form-label">Total Amount — Repair</label>
        <div class="col-sm-6 col-6">
          <number-formatter
              v-if="settled.assessmentSubtotal != null"
              v-model="settled.assessmentSubtotal"
              type="text"
              class="form-control numeric"
              format="$0,0.00"
              readonly
          ></number-formatter>
          <input v-else type="text" class="form-control" readonly>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-6 col-6 col-form-label">Settled Amount — Repair</label>
        <div class="col-sm-6 col-6">
          <number-formatter
              v-model="settled.repairSettledAmount"
              type="text"
              :class="{'form-control': true, 'numeric': true, 'red-text-important': (parseFloat(settled.repairSettledAmount || 0) < parseFloat(settled.assessmentSubtotal || 0))}"
              format="$0,0.00"
          ></number-formatter>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-6 col-6 col-form-label">Settled Date — Repair</label>
        <div class="col-sm-6 col-6">
          <b-form-datepicker
              v-model="settled.repairSettledDate"
              locale="en-GB"
              :date-format-options="{  day: '2-digit', month: '2-digit', year: 'numeric'}"
          ></b-form-datepicker>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-6 col-6 col-form-label">Legal Fees</label>
        <div class="col-sm-6 col-6">
          <number-formatter
              v-model="settled.repairLegalFees"
              type="text"
              class="form-control numeric"
              format="$0,0.00"
          ></number-formatter>
        </div>
      </div>

      <div class="row mt-4 mb-2">
        <div class="col"><h5>Rental</h5></div>
      </div>
      <div class="form-group row">
        <label class="col-sm-6 col-6 col-form-label">Total Amount — Rental</label>
        <div class="col-sm-6 col-6">
          <number-formatter
              v-if="rentalTotal != null && rentalTotal != undefined"
              v-model="rentalTotal"
              type="text"
              class="form-control numeric"
              format="$0,0.00"
              readonly
          ></number-formatter>
          <number-formatter
              v-else
              v-model="settled.rentalTotalAmount"
              type="text"
              class="form-control numeric"
              format="$0,0.00"
          ></number-formatter>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-6 col-6 col-form-label">Settled Amount — Rental</label>
        <div class="col-sm-6 col-6">
          <number-formatter
              v-model="settled.rentalSettledAmount"
              type="text"
              :class="{'form-control': true, 'numeric': true, 'red-text-important': (parseFloat(settled.rentalSettledAmount || 0) < parseFloat(rentalTotal || 0))}"
              format="$0,0.00"
          ></number-formatter>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-6 col-6 col-form-label">Settled Date — Rental</label>
        <div class="col-sm-6 col-6">
          <b-form-datepicker
              v-model="settled.rentalSettledDate"
              locale="en-GB"
              :date-format-options="{  day: '2-digit', month: '2-digit', year: 'numeric'}"
          ></b-form-datepicker>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-6 col-6 col-form-label">Legal Fees</label>
        <div class="col-sm-6 col-6">
          <number-formatter
              v-model="settled.rentalLegalFees"
              type="text"
              class="form-control numeric"
              format="$0,0.00"
          ></number-formatter>
        </div>
      </div>

      <div class="row mt-4 mb-2">
        <div class="col"><h5>Personal Injury</h5></div>
      </div>
      <div class="form-group row">
        <label class="col-sm-6 col-6 col-form-label">Total Amount — Prsnl Injury</label>
        <div class="col-sm-6 col-6">
          <number-formatter
              v-model="settled.injuryTotalAmount"
              type="text"
              class="form-control numeric"
              format="$0,0.00"
          ></number-formatter>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-6 col-6 col-form-label">Settled Amount — Prsnl Injury</label>
        <div class="col-sm-6 col-6">
          <number-formatter
              v-model="settled.injurySettledAmount"
              type="text"
              :class="{'form-control': true, 'numeric': true, 'red-text-important': (parseFloat(settled.injurySettledAmount || 0) < parseFloat(settled.injuryTotalAmount || 0))}"
              format="$0,0.00"
          ></number-formatter>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-6 col-6 col-form-label">Settled Date — Prsnl Injury</label>
        <div class="col-sm-6 col-6">
          <b-form-datepicker
              v-model="settled.injurySettledDate"
              locale="en-GB"
              :date-format-options="{  day: '2-digit', month: '2-digit', year: 'numeric'}"
          ></b-form-datepicker>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-6 col-6 col-form-label">Legal Fees</label>
        <div class="col-sm-6 col-6">
          <number-formatter
              v-model="settled.injuryLegalFees"
              type="text"
              class="form-control numeric"
              format="$0,0.00"
          ></number-formatter>
        </div>
      </div>

      <div class="row mt-4 mb-2">
        <div class="col"><h5>Assessment Fee</h5></div>
      </div>
      <div class="form-group row">
        <label class="col-sm-6 col-6 col-form-label">Total Amount — Assmt Fee</label>
        <div class="col-sm-6 col-6">
          <number-formatter
              v-model="settled.assessmentFeeTotalAmount"
              type="text"
              class="form-control numeric"
              format="$0,0.00"
              readonly
          ></number-formatter>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-6 col-6 col-form-label">Settled Amount — Assmt Fee</label>
        <div class="col-sm-6 col-6">
          <number-formatter
              v-model="settled.assessmentFeeSettledAmount"
              type="text"
              :class="{'form-control': true, 'numeric': true, 'red-text-important': (parseFloat(settled.assessmentFeeSettledAmount || 0) < parseFloat(settled.assessmentFeeTotalAmount || 0))}"
              format="$0,0.00"
          ></number-formatter>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-6 col-6 col-form-label">Legal Fees</label>
        <div class="col-sm-6 col-6">
          <number-formatter
              v-model="settled.assessmentFeeLegalFees"
              type="text"
              class="form-control numeric"
              format="$0,0.00"
          ></number-formatter>
        </div>
      </div>


      <div class="form-group row mt-5">
        <label class="col-sm-6 col-6 col-form-label">
          <strong>GRAND TOTAL — CLAIMED</strong>
        </label>
        <div class="col-sm-6 col-6">
          <number-formatter
              v-model="totalClaimed"
              type="text"
              class="form-control numeric"
              format="$0,0.00"
              readonly
          ></number-formatter>
        </div>
      </div>


      <div class="form-group row">
        <label class="col-sm-6 col-6 col-form-label">
          <strong>GRAND TOTAL — SETTLED</strong>
        </label>
        <div class="col-sm-6 col-6">
          <number-formatter
              v-model="totalSettled"
              type="text"
              :class="{'form-control': true, 'numeric': true, 'red-text-important': (parseFloat(totalSettled || 0) < parseFloat(totalClaimed || 0))}"
              format="$0,0.00"
              readonly
          ></number-formatter>
        </div>
      </div>


      <div class="form-group row">
        <label class="col-sm-6 col-6 col-form-label">
          <strong>NET TOTAL - AFTER FEES</strong>
        </label>
        <div class="col-sm-6 col-6">
          <number-formatter
              v-model="netTotalAfterFees"
              type="text"
              :class="{'form-control': true, 'numeric': true, 'red-text-important': (parseFloat(totalSettled || 0) < parseFloat(totalClaimed || 0))}"
              format="$0,0.00"
              readonly
          ></number-formatter>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import AdvancedSearch from "@/components/utility/advanced-search";
import NumberFormatter from "@/components/number-formatter";

export default {
  name: "block-settlement",
  components: {
    AdvancedSearch,
    NumberFormatter,
  },
  props: {
    rentalTotal:{
      type:[Number, null],
      default: function(){
        return 0;
      }
    },
    assessmentSubtotal: {
      type: [String, Number],
      default: function () {
        return null;
      },
    },
    settled: {
      type: Object,
      default: function(){
        return {
          repairSettledAmount: null,
          repairSettledDate: null,
          repairLegalFees: null,
          rentalTotalAmount: null,
          rentalSettledAmount: null,
          rentalSettledDate: null,
          rentalLegalFees: null,
          injuryTotalAmount: null,
          injurySettledAmount: null,
          injurySettledDate: null,
          injuryLegalFees: null,
          assessmentFeeTotalAmount: null,
          assessmentFeeSettledAmount: null,
          assessmentFeeLegalFees: null,
        };
      },
    },
  },
  data(){
    return {
      hideHeader: true,
      repairSettled: 0,
      rentalSettled: 0,
      injurySettled: 0,
      assessmentSettled: 0,
    }
  },
  watch: {},
  computed: {
    totalSettled(){
      return parseFloat(this.settled.repairSettledAmount || 0)
          + parseFloat(this.settled.rentalSettledAmount || 0)
          + parseFloat(this.settled.injurySettledAmount || 0)
          + parseFloat(this.settled.assessmentFeeSettledAmount || 0);
    },
    totalClaimed(){
      return parseFloat(this.rentalTotal || 0)
          + parseFloat( this.settled.assessmentSubtotal || 0)
          + parseFloat(this.settled.injuryTotalAmount || 0)
          + parseFloat(this.settled.assessmentFeeTotalAmount || 0);
    },
    totalFees(){
      return parseFloat(this.settled.repairLegalFees || 0)
          + parseFloat( this.settled.rentalLegalFees || 0)
          + parseFloat(this.settled.injuryLegalFees || 0)
          + parseFloat(this.settled.assessmentFeeLegalFees || 0);
    },
    totalRepair(){
      return parseFloat((this.settled.assessmentSubtotal * 1.1).toFixed(2));
    },
    netTotalAfterFees(){
      return this.totalSettled - this.totalFees;
    },
  },
}
</script>

<style scoped>
  .red-text-important {
    color: red !important;
  }
</style>
