<template>
    <div class="row">
        <div class="col-md-4 pr-0">
            <block-rental-details :ref="currentRefName" v-on="$listeners" :details="rental.details" :isAllDisable="!isRentalAllocationRequiredYes" :propsHirerNames="propsHirerNames">

            </block-rental-details>
        </div>
        <div class="col-md-4">
            <block-rental-vehicle :vehicle="rental.vehicle" :allocation="rental.allocation" :details="rental.details" :isAllDisable="!isRentalAllocationRequiredYes">

            </block-rental-vehicle>

            <block-rental-recoveries :recoveries="rental.recoveries" :legal-firms="legalFirms" :isAllDisable="!isRentalAllocationRequiredYes" @new-legal-firm="newLegalFirm">

            </block-rental-recoveries>
        </div>
        <div class="col-md-4">
            <block-rental-allocation :allocation="rental.allocation" :isAllDisable="!isRentalAllocationRequiredYes" :tolls="rental.tolls" @add-new-toll="addNewToll" @delete-toll="deleteToll">

            </block-rental-allocation>
        </div>
    </div>
</template>
<script>
    /* eslint-disable */

    import BlockRentalDetails from "../../../../components/block-rental-details";
    import BlockRentalVehicle from "../../../../components/block-rental-vehicle";
    import BlockRentalAllocation from "../../../../components/block-rental-allocation";
    import BlockRentalRecoveries from "../../../../components/block-rental-recoveries";

    export default {
        name: 'ClaimViewRentalTabRental',
        props: {
            rental: {
                type: Object,
                default: function () {
                    return {
                        allocation: {},
                        details: {},
                        vehicle: {},
                        tolls: {},
                        recoveries: {},
                        addNewToll: false,
                        newLegalFirm: false,
                        tollsToDelete: [],
                        id: 0,
                    };
                }
            },
            propsHirerNames: Array,
            legalFirms: Array,
        },
        data: function () {
            return {};
        },
        methods:{
          addNewToll(){
            this.rental.addNewToll = true;
          },
          deleteToll(toll) {
            this.rental.tollsToDelete.push(toll.id);
          },
          newLegalFirm(newLegalFirm) {
            this.rental.newLegalFirm = newLegalFirm;
          }
        },
        computed: {
            currentRefName() {
                return 'rental' + this.rental.id + 'details';
            },
            isRentalAllocationRequiredYes() {
                if (this.rental && this.rental.details && Boolean(this.rental.details.rentalAllocationRequired) == true) {
                    return true;
                }
                return false;
            },
        },
        components: {
            BlockRentalAllocation,
            BlockRentalVehicle,
            BlockRentalDetails,
            BlockRentalRecoveries,
        },

    };
</script>
