<template>
    <div class="mb-0-75 border pt-0-5 pb-0-5  dashboard-row-element clickable" @click="goToComment(comment)">
        <div class="row">
            <div class="col-9">
                <strong>
                    {{ comment.task.name }}
                    <template v-if="comment.task.assessmentNumber"> - {{ comment.task.assessmentNumber }}</template>
                    <template v-else-if="comment.task.claimNumber"> - {{ comment.task.claimNumber }}</template>
                    <template v-if="comment.createdBy"> - {{ comment.createdBy }}</template>
                </strong>
            </div>
            <div class="col-3">
                <span class="text-danger font-nunito--bold" v-if="comment.task.priority == 'high'">High</span>
                <span class="text-warning font-nunito--bold" v-if="comment.task.priority == 'medium'">Medium</span>
                <span class="text-primary font-nunito--bold" v-if="comment.task.priority == 'low'">Low</span>
                <i class='bx bx-task text-muted pull-right'></i>
            </div>
        </div>
        <div class="row mt-0-5">
            <div class="col" style="text-overflow: ellipsis;
overflow: hidden;
white-space: nowrap;">
                {{ comment.text }}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "task-summary",
        components: {},
        props: {
            comment: {
                type: [Object, null],
                default() {
                    return {
                        "id": "0",
                        "text": "Some comments for dashboard",
                        "createdBy": "Name Surname",
                        "createdOn": "2021-10-14 12:18:48",
                        "edit": false,
                        "editAllowed": false,
                        "task": {
                            "id": "0",
                            "name": "Task to test dashboard",
                            "dueDate": null,
                            "dueTime": null,
                            "notes": "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod\r\ntempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,\r\nquis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo\r\nconsequat. Duis aute irure dolor in reprehenderit in voluptate velit esse\r\ncillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non\r\nproident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>",
                            "notesText": "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod\r\ntempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,\r\nquis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo\r\nconsequat. Duis aute irure dolor in reprehenderit in voluptate velit esse\r\ncillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non\r\nproident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
                            "type": "to-do",
                            "status": "in-progress",
                            "priority": "medium",
                            "createdAt": "2021-10-14 12:18:08",
                            "createdBy": "Name Surname",
                            "createdById": "2",
                            "assignedTo": "3",
                            "claimId": "47",
                            "assessmentId": "70",
                            "claimNumber": "CL00305",
                            "assessmentNumber": "A00311"
                        }
                    };
                }
            },
        },
        methods: {
            goToComment(comment) {
                this.$router.push(
                    {name: 'AssessmentView', params: {action: 'goToComment', assessmentId: comment.task.assessmentId, commentId: comment.id, taskId: comment.task.id}}
                );
            }
        },
    };
</script>

<style scoped>

</style>
