<template>
  <div class="dashboard-page">
    <div class="page-header">
      <h4>Dashboard</h4>
      <ol class="breadcrumbs">
        <li class="breadcrumb-item active">Dashboard</li>
      </ol>
    </div>
    <div class="widget-panel white-bg mb-0 ">
      <widget-assessments v-if="isRoleManager || isRoleAdministrator"></widget-assessments>
    </div>
    <div class="widget-panel white-bg mb-0 ">
      <widget-assessments-tabs v-if="isRoleManager || isRoleAdministrator"></widget-assessments-tabs>
    </div>
    <div class="widget-container">
      <div class="widget-panel parts-order-panel">
        <!--                <PartsOrders />-->
      </div>
      <div class="widget-panel notifications-panel">
        <!--                <Notifications />-->
      </div>
    </div>
  </div>

</template>

<script>

import WidgetAssessments from '../dashboard-widgets/assessments'
import WidgetAssessmentsTabs from '../dashboard-widgets/tabs/assessments-tabs'
import {mapGetters} from "vuex";

export default {
  name: "dashboard",
  computed:{
    ...mapGetters({
      isRoleManager: "currentUser/isRoleManager",
      isRoleAdministrator: "currentUser/isRoleAdministrator",
    }),
  },
  components: {
    WidgetAssessments,
    WidgetAssessmentsTabs,
  },
}
</script>

<style scoped>

</style>
