<script>
    // import imageEditor from './image-editor';

    export default {
        name: 'image-gallery',
        props: {
            images: {
                type: Array,
                default() {
                    return [];
                }
            },
            currentIndex: {
                type: Number || null,
                default() {
                    return 0;
                }
            },
            useEditor: {
                type: Boolean,
                default() {
                    return false;
                }
            },
            isDownload: {
                type: Boolean,
                default() {
                    return false;
                }
            },
            isRemove: {
                type: Boolean,
                default() {
                    return false;
                }
            },
        },
        data: function(th) {
            return {
                loading: false,
                cancelTimeout: null,
                isEditing: false,
                zoomScale: 1,
                currentIndex2: th.currentIndex,
            };
        },
        computed: {
            selectedIndex: function () {
              return this.currentIndex2
            },
            isTechnicalUser: function () {
                let userInfo = this.$store.state.userInfo;
                if (userInfo && userInfo.group_id == 4) {
                    return true;
                }
                return false;
            },
            currentNo: function () {
                return this.selectedIndex + 1;
            },
            countImages: function () {
                return this.images ? this.images.length : 0;
            },
            currentImageUrl: function () {
                return this.images && this.selectedIndex >= 0 && this.selectedIndex < this.countImages
                    ? this.images[this.selectedIndex].url : '';
            },
            currentImageId: function () {
                return this.images && this.selectedIndex >= 0 && this.selectedIndex < this.countImages
                    ? this.images[this.selectedIndex].id : 0;
            },
            nextImageUrl: function () {
                const idx = this.nextIndex;
                return idx >= 0 ? this.images[idx].url : '';
            },
            prevImageUrl: function () {
                const idx = this.prevIndex;
                return idx >= 0 ? this.images[idx].url : '';
            },
            nextIndex: function () {
                if (this.countImages > 0) {
                    return this.selectedIndex < this.countImages - 1 ? this.selectedIndex + 1 : 0;
                } else {
                    return -1;
                }
            },
            prevIndex: function () {
                if (this.countImages > 0) {
                    return this.selectedIndex > 0 ? this.selectedIndex - 1 : this.countImages - 1;
                } else {
                    return -1;
                }
            },
            disableZoom() {
                return this.zoomScale === 1
            },
        },
        watch: {
          currentIndex: function (index) {
            this.currentIndex2 = index
          }
        },
        methods: {
            onClickClose: function () {
                if (this.isEditing) {
                    this.isEditing = false;
                } else {
                    this.$emit('close-gallery');

                    // focus on pin image list if any
                    this.$nextTick(() => {
                        const imagePinArea = this.$parent.$refs['image-pin-area'];
                        if (imagePinArea) {
                            imagePinArea.$refs['image-pin-area'].focus();
                        }
                    });
                }
            },
            onClickNext: function () {
                if (this.countImages > 1) {
                    this.currentIndex2 = this.nextIndex;
                    this.isEditing = false;
                    this.onImageLoadStart();
                }
            },
            onClickPrev: function () {
                if (this.countImages > 1) {
                    this.currentIndex2 = this.prevIndex;
                    this.isEditing = false;
                    this.onImageLoadStart();
                }
            },
            onClickEdit: function () {
                if (this.useEditor) {
                    this.isEditing = true;
                }
            },
            onClickDownload: function () {
                let fileLink = document.createElement('a');
                let imageUrl = this.currentImageUrl;
                fileLink.href = imageUrl;
                fileLink.target = "_blank";
                let names = imageUrl.split('/');
                fileLink.setAttribute('download', names.length > 0 ? names[names.length - 1] : 'image.jpg');
                fileLink.click();
            },
            onImageLoadStart: function () {
                this.cancelTimeout = setTimeout(() => {
                    if (this.cancelTimeout) {
                        this.loading = true;
                    }
                }, 300);
            },
            onImageLoaded: function () {
                this.loading = false;
                if (this.cancelTimeout) {
                    clearTimeout(this.cancelTimeout);
                    this.cancelTimeout = null;
                }
            },
            onImageSaved: function () {
                this.$emit('saved-image', this.currentImageId);
                const img = new Image();
                img.src = this.images[this.selectedIndex].url + '?version=' + (new Date()).getTime().toString();
            },
            onImageZoomIn() {
                if ( this.zoomScale < 2 ) {
                this.zoomScale += 0.5;
                } else {
                this.zoomScale = 2;
                }
                // manually change the component scale
                const zoomImage = this.$refs.zoomImage
                // only normal and 2 scale
                if (this.zoomScale === 2) {
                  zoomImage.scaleFactor = parseInt(this.zoomScale)
                  zoomImage.$refs.zoom.style.transform = `scale(${this.zoomScale})`;
                } else {
                  zoomImage.scaleFactor = 1
                  zoomImage.$refs.zoom.style.transform = ``;
                }
                zoomImage.initEventResized()
            },
            onImageZoomOut() {
                if ( this.zoomScale > 1 && this.zoomScale <= 2 ) {
                this.zoomScale -= 0.5;
                }
                // manually change the component scale
                const zoomImage = this.$refs.zoomImage
                // only normal and 2 scale
                if (this.zoomScale === 2) {
                  zoomImage.scaleFactor = parseInt(this.zoomScale)
                  zoomImage.$refs.zoom.style.transform = `scale(${this.zoomScale})`;
                } else {
                  zoomImage.scaleFactor = 1
                  zoomImage.$refs.zoom.style.transform = ``;
                }
                zoomImage.initEventResized()
            },
            onClickRemove() {
                const index = this.currentNo - 1;
                //  this.onClickNext();
                this.$emit('remove-image', index, this.images[index]);
                if (this.countImages > 1) {
                    this.selectedIndex = this.nextIndex == 0 ? 0 : this.nextIndex - 1;
                    this.isEditing = false;
                } else {
                    this.selectedIndex = 0;
                }
                this.onImageLoadStart();

            },
            handleKeydown(e) {
                e.preventDefault();
                switch (e.keyCode) {
                    case 39: // right arrow
                    case 40: // bottom arrow
                    {
                        this.onClickNext();
                        break;
                    }
                    case 37: // left arrow
                    case 38: // up arrow

                    {
                        this.onClickPrev();
                        break;
                    }
                    case 9: // focus to download
                    {
                        this.$refs['button-download'].focus();
                        break;
                    }
                    case 32: // space
                    case 13: // enter
                    {
                        this.$refs['button-download'].click();
                        break;
                    }
                    case 27: // esc
                    {
                        this.onClickClose();
                        break;
                    }
                    default: {
                        // do nothing
                        e.stopPropagation();
                    }
                }

            }
        },
        components: {
            // 'image-edditor': imageEditor
        },
        mounted() {
            this.$refs['image-gallery'].focus();
        }
    };
</script>

<template>
    <div ref="image-gallery" @keydown="handleKeydown" tabindex="0">
        <div class="gallery-bg gallery-img-mobile gallery-ready"></div>
        <div class="gallery-wrap gallery-gallery gallery-close-btn-in gallery-auto-cursor gallery-img-mobile gallery-ready"
             tabindex="-1"
             style="overflow: hidden auto;">
            <div class="gallery-container gallery-image-holder gallery-s-ready container-fluid">
                <div class="row">
                    <div class="col-12 col-md-11 offset-md-1 offset-lg-0 col-lg-9" style="background-color: white; max-width: 896px">
                        <div class="gallery-content d-flex flex-column justify-content-center">
                            <div class="gallery-figure">
                                <image-edditor v-if="isEditing"
                                    :image-id="currentImageId"
                                    :image-url="currentImageUrl"
                                    @saved-image="onImageSaved"
                                    :scale="zoomScale"
                                    :disabled="disableZoom"
                                ></image-edditor>
                                <button v-if="!loading" title="Close (Esc)" type="button" class="gallery-close"
                                        @click="onClickClose">
                                    <i class='bx bx-x'></i>
                                </button>
                                <!-- <image-edditor v-if="isEditing" :image-id="currentImageId" :image-url="currentImageUrl"
                                               @saved-image="onImageSaved"></image-edditor> -->
                                <figure class="position-relative">
                                    <zoom-on-hover
                                        ref="zoomImage"
                                        v-show="!loading"
                                        class="gallery-img"
                                        style="width: 100%; height: 100%; object-fit: scale-down; object-position: right; max-height: 80vh;"
                                        :imgNormal="currentImageUrl"
                                        @loaded="onImageLoaded"
                                        @loadstart="onImageLoadStart"
                                        :scale="zoomScale"
                                        :disabled="disableZoom"
                                    />
                                    <link rel="preload" :href="nextImageUrl" as="image">
                                    <link rel="preload" :href="prevImageUrl" as="image">
                                    <div v-show="loading" class="gallery-img" style="color: white; font-weight: bold">
                                        Loading image {{ currentNo }}...
                                    </div>
                                    <!-- zoom actions -->
                                    <div class="actions actions--topleft">
                                        <button class="actions__item" :class="{'actions__item--active': !disableZoom}" @click.prevent="onImageZoomIn">
                                            <i class='bx bx-zoom-in'></i>
                                            <div v-if="false && !disableZoom" class="actions__zoom-info-circle">{{zoomScale}}x</div>
                                        </button>
                                        <button class="actions__item" @click.prevent="onImageZoomOut">
                                            <i class='bx bx-zoom-out' ></i>
                                        </button>
                                        <transition name="slide-fade">
                                            <div v-if="!disableZoom" class="actions__info">
                                            {{ zoomScale === 2 ? 'Double Zoom' : 'Normal Zoom' }}
                                            </div>
                                        </transition>
                                    </div>
                                    <b-button v-if="isRemove"
                                              @click.prevent="onClickRemove"
                                              class="btn btn--remove">
                                        <i class='bx bx-trash'></i>
                                    </b-button>
                                    <figcaption v-if="!loading">
                                        <div class="gallery-bottom-bar d-flex flex-column justify-content-start align-items-end">
                                            <div class="gallery-counter" v-if="countImages > 0">{{ currentNo }} of
                                                {{ countImages }}
                                            </div>
                                            <div v-if="isDownload"
                                                 class="download_edit_wrap btn-container d-flex justify-content-end">
                                                <!--                          <button v-if="useEditor" class="btn btn-primary order-1 order-md-0 btn&#45;&#45;edit waves-effect waves-light sec_btn" @click="onClickEdit">Edit</button>-->
                                                <button ref="button-download" class="button--download btn btn-primary order-0 order-md-1 btn--download waves-effect waves-light"
                                                        @click.stop="onClickDownload">Download
                                                </button>
                                            </div>
                                        </div>
                                    </figcaption>
                                </figure>
                            </div>
                        </div>
                        <button v-if="countImages > 1" title="Previous (Left arrow key)" type="button"
                                @click="onClickPrev"
                                class="gallery-arrow gallery-arrow-left gallery-prevent-close">
                            <i class='bx bx-chevron-left'></i>
                        </button>
                        <button v-if="countImages > 1" title="Next (Right arrow key)" type="button" @click="onClickNext"
                                class="gallery-arrow gallery-arrow-right gallery-prevent-close">
                            <i class='bx bx-chevron-right'></i>
                        </button>
                    </div>
                </div>
                <div class="gallery__gray-bg"></div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .btn--remove {
        position: absolute;
        top: 15px;
        right: 15px;
        width: auto !important;
        font-size: 20px !important;
        border: 0;

        &:hover {
            .theme-repairer & {
                color: var(--repairer-color);
            }

            .theme-supplier & {
                color: var(--info);
            }
        }
    }

    .gallery {
        &-content {
            min-height: 100vh;
        }

        &__gray-bg {
            display: none;
        }
    }

    button.btn {
        font-size: 13px;
        line-height: 18px;

        &--edit {
            margin-left: 15px;
            background-color: transparent;
            color: #00B050;
            border-color: #00B050;

            &:hover {
                color: #FFFFFF;
                background-color: #00B050;
            }
        }

        &--download {
            margin-left: 15px;
            color: #FFFFFF;
            background-color: #00B050;
            border-color: #00B050;
        }
    }

    .gallery-bg {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1042;
        overflow: hidden;
        position: fixed;
        background: #FFFFFF;
    }

    .gallery-wrap {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10010;
        position: fixed;
        outline: none !important;
        background-color: #F3F4F5;
        -webkit-backface-visibility: hidden;
    }

    .gallery-container {
        text-align: center;
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        padding-left: 240px;
        min-height: 100vh;
        box-sizing: border-box;
        background-color: rgba(27, 30, 56, 0.05);

        .compact-sidebar & {
            padding-left: 70px;
        }
    }

    // .gallery-container:before {
    //   content: '';
    //   display: inline-block;
    //   height: 100%;
    //   vertical-align: middle;
    // }

    .gallery-align-top .gallery-container:before {
        display: none;
    }

    .gallery-content {
        position: relative;
        display: inline-block;
        padding-top: 59px; // site-header height
        vertical-align: middle;
        margin: 0 auto;
        text-align: left;
        z-index: 1045;
    }

    .gallery-inline-holder .gallery-content,
    .gallery-ajax-holder .gallery-content {
        width: 100%;
        cursor: auto;
    }

    .gallery-ajax-cur {
        cursor: progress;
    }

    .gallery-zoom-out-cur, .gallery-zoom-out-cur .gallery-image-holder .gallery-close {
        cursor: -moz-zoom-out;
        cursor: -webkit-zoom-out;
        cursor: zoom-out;
    }

    .gallery-zoom {
        cursor: pointer;
        cursor: -webkit-zoom-in;
        cursor: -moz-zoom-in;
        cursor: zoom-in;
    }

    .gallery-auto-cursor .gallery-content {
        cursor: auto;
    }

    .gallery-close,
    .gallery-arrow,
    .gallery-preloader,
    .gallery-counter {
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
    }

    .gallery-loading.gallery-figure {
        display: none;
    }

    .gallery-hide {
        display: none !important;
    }

    .gallery-preloader {
        color: #CCCCCC;
        position: absolute;
        top: 50%;
        width: auto;
        text-align: center;
        margin-top: -0.8em;
        left: 8px;
        right: 8px;
        z-index: 1044;
    }

    .gallery-preloader a {
        color: #CCCCCC;
    }

    .gallery-preloader a:hover {
        color: #FFFFFF;
    }

    .gallery-s-ready .gallery-preloader {
        display: none;
    }

    .gallery-s-error .gallery-content {
        display: none;
    }

    button.gallery-close,
    button.gallery-arrow {
        overflow: visible;
        cursor: pointer;
        background: transparent;
        border: 0;
        -webkit-appearance: none;
        display: block;
        outline: none;
        padding: 0;
        z-index: 1046;
        box-shadow: none;
        touch-action: manipulation;
    }

    button::-moz-focus-inner {
        padding: 0;
        border: 0;
    }

    .gallery-close {
        width: 44px;
        height: 28px;
        line-height: unset;
        position: relative;
        right: 0;
        top: 0;
        text-decoration: none;
        text-align: center;
        opacity: 0.65;
        padding: 0 0 0 0;
        color: #FFFFFF;
        font-style: normal;
        font-size: 48px;
        font-family: Arial, Baskerville, monospace;
    }

    .gallery-close:hover,
    .gallery-close:focus {
        opacity: 1;
    }

    .gallery-close:active {
        top: 1px;
    }

    .gallery-close-btn-in .gallery-close {
        color: #333333;
    }

    .gallery-image-holder .gallery-close,
    .gallery-iframe-holder .gallery-close {
        position: absolute;
        width: 100%;
        height: auto;

        color: #000000;
        right: -6px;
        text-align: right;
        padding-top: 15px;
        line-height: 48px;
    }

    .gallery-counter {
        color: #1C1F39;
        font-size: 16px;
        line-height: 22px;
        margin: 15px 0;
        white-space: nowrap;
    }

    .gallery-arrow {
        position: absolute;
        opacity: 0.65;
        margin: 0;
        top: 50%;
        font-size: 48px;
        margin-top: -55px;
        padding: 0;
        width: 70px;
        height: 110px;
        -webkit-tap-highlight-color: transparent;
    }

    .gallery-arrow:active {
        // margin-top: -54px;
    }

    .gallery-arrow:hover,
    .gallery-arrow:focus {
        opacity: 1;
    }

    .gallery-arrow:before,
    .gallery-arrow:after {
        // content: '';
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        left: 0;
        top: 0;
        margin-top: 35px;
        margin-left: 35px;
        border: medium inset transparent;
    }

    .gallery-arrow:after {
        border-top-width: 13px;
        border-bottom-width: 13px;
        top: 8px;
    }

    .gallery-arrow:before {
        border-top-width: 21px;
        border-bottom-width: 21px;
        opacity: 0.7;
    }

    .gallery-arrow-left {
        left: 0;
    }

    .gallery-arrow-left:after {
        border-right: 17px solid #FFFFFF;
        margin-left: 31px;
    }

    .gallery-arrow-left:before {
        margin-left: 25px;
        border-right: 27px solid #3F3F3F;
    }

    .gallery-arrow-right {
        right: 0;
    }

    .gallery-arrow-right:after {
        border-left: 17px solid #FFFFFF;
        margin-left: 39px;
    }

    .gallery-arrow-right:before {
        border-left: 27px solid #3F3F3F;
    }

    .gallery-iframe-holder {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .gallery-iframe-holder .gallery-content {
        line-height: 0;
        width: 100%;
        max-width: 900px;
    }

    .gallery-iframe-holder .gallery-close {
        top: -40px;
    }

    .gallery-iframe-scaler {
        width: 100%;
        height: 0;
        overflow: hidden;
        padding-top: 56.25%;
    }

    .gallery-iframe-scaler iframe {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
        background: #000000;
    }

    /* Main image in popup */
    img.gallery-img {
        display: block;
        width: 100%;
        height: auto;
        max-width: 100%;
        min-height: 20vh;
        margin: 0 auto;
        line-height: 0;
        background-color: whitesmoke;
        box-sizing: border-box;
        border-radius: 5px;
        object-fit: contain;
    }

    /* The shadow behind the image */
    .gallery-figure {
        line-height: 0;
    }

    .gallery-figure:after {
        content: '';
        position: absolute;
        left: 0;
        top: 40px;
        bottom: 40px;
        display: block;
        right: 0;
        width: auto;
        height: auto;
        z-index: -1;
    }

    .gallery-figure small {
        color: #BDBDBD;
        display: block;
        font-size: 12px;
        line-height: 14px;
    }

    .gallery-figure {
        figure {
            margin: 0;
            overflow: hidden;
        }
    }

    .gallery-bottom-bar {
        cursor: auto;
    }

    .gallery-title {
        text-align: left;
        line-height: 18px;
        color: #F3F3F3;
        word-wrap: break-word;
        padding-right: 36px;
    }

    .gallery-image-holder .gallery-content {
        max-width: 90%;
    }

    .gallery-gallery .gallery-image-holder .gallery-figure {
        // cursor: pointer;
    }

    .actions {
        position: absolute;
        display: grid;
        gap: 10px;
        grid-template-columns: 44px auto;
        align-items: center;
        justify-content: start;
    }

    .actions::after {
        content: "";
        display: table;
        clear: both;
    }

    .actions.actions--topleft {
        top: 0;
        left: 0;
        padding: 10px;
    }

    .actions__item {
        height: 44px;
        width: 44px;
        font-size: 24px;
        border-radius: 3px;
        align-items: center;
        justify-content: center;
        border: 0;
        color: black;
        cursor: pointer;
        position: relative;
    }

    .actions__item:hover,
    .actions__item--active {
        color: #00B050;
    }

    .actions__item:active {
        transform: scale(0.95);
    }

    .actions__info {
        display: grid;
        place-items: center;
        grid-column: 1 / 3;
        color: black;
        font-weight: 600; /* semi-bold */
        background-color: #efefef;
        border-radius: 3px;
        padding: 14px 0;
        pointer-events: none;
        transition: all 250ms cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    .actions__zoom-info-circle {
        position: absolute;
        background-color: #5E79FF;
        height: 28px;
        width: 28px;
        border-radius: 50%;
        color: white;
        font-size: 9px;
        display: grid;
        place-items: center;
        left: -14px;
        top: -14px;
        box-shadow: 2px 2px 3px rgba(56, 38, 161, 0.2);
    }

    .slide-fade-enter,
    .slide-fade-leave-to {
        transform: translateY(-5px) scale(1.1);
        opacity: 0;
    }

    @media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
        /**
        * Remove all paddings around the image on small screen
        */
        .gallery-container {
            padding-left: 70px;
        }
        .gallery-img-mobile .gallery-image-holder {
            padding-left: 0;
            padding-right: 0;
        }
        .gallery-img-mobile img.gallery-img {
            padding: 0;
        }
        .gallery-img-mobile .gallery-figure:after {
            top: 0;
            bottom: 0;
        }
        .gallery-img-mobile .gallery-figure small {
            display: inline;
            margin-left: 5px;
        }
        .gallery-img-mobile .gallery-bottom-bar:empty {
            padding: 0;
        }
        .gallery-img-mobile .gallery-counter {
            right: 5px;
            top: 3px;
        }
        .gallery-img-mobile .gallery-close {
            top: 0;
            right: 0;
            width: 35px;
            height: 35px;
            line-height: 35px;
            background: rgba(0, 0, 0, 0.6);
            position: fixed;
            text-align: center;
            padding: 0;
        }
    }

    @media all and (max-width: 900px) {

        .gallery-arrow {
            -webkit-transform: scale(0.75);
            transform: scale(0.75);
        }

        .gallery-arrow-left {
            -webkit-transform-origin: 0;
            transform-origin: 0;
        }

        .gallery-arrow-right {
            -webkit-transform-origin: 100%;
            transform-origin: 100%;
        }

        .gallery-img-mobile .gallery-bottom-bar {
            margin-bottom: 3rem;
        }

        .gallery-image-holder {

            .gallery-content {
                max-width: 80%;
            }

            .gallery-close {
                padding-top: 0;
                right: -15%;
                font-size: 28px;
            }

        }

        .gallery {
            &-container {
                padding-left: 160px; // compensate offset
                background-color: white;

                .compact-sidebar & {
                    padding-left: 6px;
                    padding-right: 6px;
                }
            }

            &-arrow {
                margin-top: -95px;
            }

            &-content {
                min-height: 72vh;
                padding-top: 3rem;
            }

            &__gray-bg {
                display: block;
                width: 100%;
                min-height: 38vh;
                background-color: rgba(27, 30, 56, 0.05);
            }

            &-arrow .bx {
                font-size: 42px;
            }

            &-arrow-left {
                left: 15px;
            }

            &-arrow-right {
                right: 15px;
            }
        }
    }

    /**
     * Mobile Phones
     */
    @media all and (max-width: 426px) {

        .gallery-image-holder {

            .gallery-content {
                max-width: 100%;
            }

            .gallery-close {
                padding-top: 0;
                right: unset;
                font-size: 28px;
            }

        }

        .gallery {

            &-container {
                padding-left: 0;
                padding-right: 0;
            }

            &-arrow {
                margin-top: -95px;
            }

            &-arrow .bx {
                border-radius: 50%;
                background-color: rgba(255, 255, 255, 0.5);
                font-size: 38px;
            }

            &-arrow-left {
                left: 10px;
            }

            &-arrow-right {
                right: 10px;
            }
        }
        .btn-container {
            width: 100%;
        }

        button.btn {
            display: block;
            width: 100%;

            &--edit {
                margin-left: 15px;
            }

            &--download {
                margin-left: 0;
            }

        }
    }
</style>
