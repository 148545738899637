<template>
  <div class="card">
    <div class="card-header bg-navyblue">
      <strong>Personal Injury Information</strong>
    </div>
    <div class="card-block bg-white">
      <div class="form-group row">
        <label class="col-sm-4 col-4 col-form-label px-0">P/I Claim Number</label>
        <div class="col-sm-8 col-8">
          <input v-model="settled.injuryClaimNumber" type="text" class="form-control">
        </div>
      </div>
      <div class="form-group row">
        <label for="owner" class="col-sm-4 col-4 col-form-label px-0">P/I Claim Total</label>
        <div class="col-sm-8 col-8">
          <number-formatter
              v-model="settled.injuryTotalAmount"
              type="text"
              class="form-control numeric"
              format="$0,0.00"
          ></number-formatter>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NumberFormatter from "@/components/utility/number-formatter";

export default {
  name: "block-personal-injury-information",
  components: {
    NumberFormatter,
  },
  props: {
    settled: {
      type: Object,
      default: function(){
        return {
          repairSettledAmount: null,
          repairSettledDate: null,
          repairLegalFees: null,
          rentalTotalAmount: null,
          rentalSettledAmount: null,
          rentalSettledDate: null,
          rentalLegalFees: null,
          injuryClaimNumber: null,
          injuryTotalAmount: null,
          injurySettledAmount: null,
          injurySettledDate: null,
          injuryLegalFees: null,
          assessmentFeeTotalAmount: null,
          assessmentFeeSettledAmount: null,
          assessmentFeeLegalFees: null,
        };
      },
    },
  },
}
</script>

<style scoped>

</style>
