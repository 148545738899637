<script>
    /* eslint-disable */
    import multiselect from 'vue-multiselect';
    import NumberFormatter from '@/components/number-formatter';
    import _ from "lodash";

    export default {
        name: 'block-insured-vehicle',
        props: {
            vehicle: {
                type: Object,
                default: function () {
                    return {
                        rego: '',
                        make: '',
                        model: '',
                        series: '',
                        badge: '',
                        colour: '',
                        colourVariant: '',
                        vin: '',
                        dom: '',
                        complianceDate: '',
                        body: '',
                        odometer: 0,
                        transmission: null,
                    };
                }
            },
        },
        data: function () {
            return {};
        },
        methods: {},

        computed: {
            rego: {
                get() {
                    return _.toUpper(this.vehicle.rego);
                },
                set(value) {
                    this.vehicle.rego = _.toUpper(value);
                }
            },
            vin: {
                get() {
                    return _.toUpper(this.vehicle.vin);
                },
                set(value) {
                    this.vehicle.vin = _.toUpper(value);
                }
            },
        },
        watch: {},
        components: {multiselect, NumberFormatter}

    };
</script>
<template>
    <div class="card">
        <div class="card-header bg-navyblue">
            <strong>Vehicle</strong>
        </div>
        <div class="card-block bg-white">

            <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Rego</label>
                <div class="col-sm-8 col-8">
                    <input type="text" v-model="rego" class="form-control">
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Make</label>
                <div class="col-sm-8 col-8" ref="multi1">
                    <input type="text" v-model="vehicle.make" class="form-control">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Model</label>
                <div class="col-sm-8 col-8">
                    <input type="text" v-model="vehicle.model" class="form-control">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Series</label>
                <div class="col-sm-8 col-8">
                    <input type="text" v-model="vehicle.series" class="form-control">
                </div>
            </div>


            <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Badge</label>
                <div class="col-sm-8 col-8">
                    <input type="text" v-model="vehicle.badge" class="form-control">
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Body</label>
                <div class="col-sm-8 col-8">
                    <input type="text" v-model="vehicle.body" class="form-control">
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">D.O.M</label>
                <div class="col-sm-8 col-8">
                    <input type="text" v-model="vehicle.dom" class="form-control">
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Compliance Date</label>
                <div class="col-sm-8 col-8">
                    <input type="text" v-model="vehicle.complianceDate" class="form-control">
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">VIN</label>
                <div class="col-sm-8 col-8">
                    <input type="text" v-model="vin" class="form-control text-uppercase">
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Colour</label>
                <div class="col-sm-8 col-8">

                    <input type="text" v-model="vehicle.colour" class="form-control">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Colour Variant</label>
                <div class="col-sm-8 col-8">
                    <input type="text" v-model="vehicle.colourVariant" class="form-control">
                </div>
            </div>


            <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Odometer</label>
                <div class="col-sm-8 col-8">
                    <number-formatter v-model="vehicle.odometer" class="form-control numeric" format="0"></number-formatter>
                </div>
            </div>


            <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Transmission</label>
                <div class="col-sm-8 col-8">
                    <multiselect
                            v-model="vehicle.transmission"
                            :options="['Automatic', 'Manual']"
                            :showLabels="false"
                            :option-height="29"
                            :max-height="203"
                            :close-on-select="true"
                            :allow-empty="false"
                            placeholder="Select Transmission"
                    />
                </div>
            </div>
        </div>
    </div>

</template>

<style scoped>
    .V3 .new-card .card {
        margin-bottom: 0px;
    }

    .cards-edit-page .card-block {
        padding-right: 0;
    }

    .V3 body.V3 .multiselect__select {
        height: 35px;
        right: 0px;
        top: 0px;
    }

    .V3 .card-new-body .card-block {
        padding: 0px;
    }

    .V3 .col-form-label {
        padding: 8px 0;
    }
    @media (max-width: 575.98px) {
        .V3 .col-block .col-form-label{
            padding: 8px 15px;
        }
    }
</style>
