<script>
    /* eslint-disable */

    import BlockAddress from "./block-address";
    import AdvancedSearchWithNew from "@/components/utility/advancedSearchWithNew";

    export default {
        name: 'block-claims-repairer',
        props: {
          repairer: {
            type: Object,
            default: function () {
              return {
                name: 'Name',
                approvalStatus: null,
                licenseNumber: '',
                email: '',
                phone: '',
                address: {},
              };
            }
          },
          innerRepairer: {
            type: Object,
            default: function () {
              return {
                businessName: null,
                approvalStatus: null,
                licenseNumber: '',
                email: '',
                phone: '',
                address: {},
              };
            }
          },
          repairers: {
            type: Array,
            default: function () {
              return [];
            }
          },
          isReadonly:{
              type: Boolean,
              default: false,
          },
          changeNotAllowed: {
            type: Boolean,
            default: false,
          }
        },
        data: function () {
            return {
            };
        },
        methods: {
        },
        computed: {
        },
        watch: {
        },
        components: {
          BlockAddress,
          AdvancedSearchWithNew,
        }
    };
</script>
<template>
    <div class="card">
      <div class="card-header bg-navyblue">
          <strong>Repairer</strong>
      </div>

      <div class="card-block bg-white">
        <div class="form-group row">
          <label class="col-sm-4 col-4 col-form-label">Repairer Name</label>
          <div class="col-sm-8 col-8">
            <input type="text" v-model="repairer.name" class="form-control" :readonly="isReadonly">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 col-4 col-form-label">Repairer Approval status</label>
          <div class="col-sm-8 col-8">
            <label class="form-check-inline radio">
              <input v-model="repairer.approvalStatus" class="form-check-input" type="radio" value="true"
                     @click="(!!repairer.approvalStatus) ? repairer.approvalStatus = null : null"
                     @change="repairer.approvalStatus = true" :disabled="isReadonly">
              <span class="icon"><i class='bx bx-check'></i></span>
              <span class="text">Pre Aprvd</span>
            </label>
            <label class="form-check-inline radio" style="width:130px;">
              <input v-model="repairer.approvalStatus" class="form-check-input" type="radio" value="false"
                     @click="(!repairer.approvalStatus) ? repairer.approvalStatus = null : null"
                     @change="repairer.approvalStatus = false" :disabled="isReadonly">
              <span class="icon"><i class='bx bx-check'></i></span>
              <span class="text">Custom Choice</span>
            </label>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-4 col-4 col-form-label">License Nbr</label>
          <div class="col-sm-8 col-8">
            <input type="text" v-model="repairer.licenseNumber" class="form-control" :readonly="isReadonly">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 col-4 col-form-label">Email</label>
          <div class="col-sm-8 col-8">
            <input type="text" v-model="repairer.email" class="form-control" :readonly="isReadonly">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 col-4 col-form-label">Phone Nbr</label>
          <div class="col-sm-8 col-8">
            <input type="text" v-model="repairer.phone" class="form-control" :readonly="isReadonly">
          </div>
        </div>
        <block-address :address="repairer.address" :isReadonly="isReadonly"></block-address>
      </div>
    </div>

</template>

<style scoped>
    .V3 .new-card .card {
        margin-bottom: 0px;
    }

    .cards-edit-page .card-block {
        padding-right: 0;
    }

    .V3 body.V3 .multiselect__select {
        height: 35px;
        right: 0px;
        top: 0px;
    }

    .V3 .card-new-body .card-block {
        padding: 0px;
    }

    .V3 .col-form-label {
        padding: 8px 0;
    }
    @media (max-width: 575.98px) {
        .V3 .col-block .col-form-label{
            padding: 8px 15px;
        }
    }
</style>
