<script>
    /* eslint-disable */

    export default {
        name: 'block-other-party-reasons-for-hire',
        props: {
            hideHeader: {
                type: Boolean,
                default: function () {
                    return false;
                }
            },
            reasons: {
                type: Array,
                default: function () {
                    return [];
                }
            },
        },
        data: function () {
            return {
                object4: {
                    parking: 'Motorbike parking',
                    busLanes: 'Use of bus lanes',
                    laneSplit: 'Ability to lane split',
                    cheaperTolls: 'Cheaper tolls',
                    cheaperPetrol: 'Cheaper petrol',
                },
                object5: {
                    safety: 'Safety',
                    powerAndBraking: 'Familiarity of power & braking',
                    carrying: 'Carrying pillions',
                },
            };
        },
    };

</script>
<template>
    <div class="card">
        <div  v-if="!hideHeader" class="card-header bg-navyblue">
            <strong>Reasons for Hire</strong>
        </div>
        <div class="card-block bg-white">
            <template v-for="(item, index) in reasons">
                <div v-if="item.condition === true" class="form-group row">
                    <label class="col-sm-6 col-6 col-form-label">{{item.name}}</label>
                    <div class="col-sm-6 col-6">
                        <template v-if="Array.isArray(item.additional) && index == 1">
                            <div>
                                <label class="form-check-inline radio">
                                    <input class="form-check-input" type="radio" :value="true" v-model="item.condition" @click="(!!item.condition)?item.condition=null:null" :checked="item.condition === true">
                                    <span class="icon"><i class='bx bx-check'></i></span>
                                    <span class="text">Yes</span>
                                </label>
                                <label class="form-check-inline radio">
                                    <input class="form-check-input" type="radio" :value="false" v-model="item.condition" @click="(!item.condition)?item.condition=null:null" :checked="item.condition === false">
                                    <span class="icon"><i class='bx bx-check'></i></span>
                                    <span class="text">No</span>
                                </label>
                            </div>
                            <textarea   v-model="item.additional[0]" class="form-control" style="margin-bottom: 10px; min-height: 100px"></textarea>
                            <input  type="text" v-model="item.additional[1]" class="form-control">
                        </template>
                        <template v-else-if="Array.isArray(item.additional)">
                            <div>
                              <label class="form-check-inline radio">
                                <input class="form-check-input" type="radio" :value="true" v-model="item.condition" @click="(!!item.condition)?item.condition=null:null" :checked="item.condition === true">
                                <span class="icon"><i class='bx bx-check'></i></span>
                                <span class="text">Yes</span>
                              </label>
                              <label class="form-check-inline radio">
                                <input class="form-check-input" type="radio" :value="false" v-model="item.condition" @click="(!item.condition)?item.condition=null:null" :checked="item.condition === false">
                                <span class="icon"><i class='bx bx-check'></i></span>
                                <span class="text">No</span>
                              </label>
                            </div>
                            <input v-for="(elem, key) in item.additional" type="text" v-model="item.additional[key]" class="form-control">
                        </template>
                        <template v-else-if="typeof item.additional === 'object' && item.additional !== null">
                            <ul class="list-unstyled">
                                <template v-for="(elemAdditional, keyAdditional) in item.additional">
                                    <li v-if="index == 4">
                                        <label class="form-check-inline checkbox">
                                            <input class="form-check-input" type="checkbox" v-model="item.additional[keyAdditional]" :value="true">
                                            <span class="icon"><i class='bx bx-check'></i></span>
                                            <span class="text">
                                                {{object4[keyAdditional]}}
                                            </span>
                                        </label>
                                    </li>
                                    <li v-if="index == 5">
                                        <label class="form-check-inline checkbox">
                                            <input class="form-check-input" type="checkbox" v-model="item.additional[keyAdditional]" :value="true">
                                            <span class="icon"><i class='bx bx-check'></i></span>
                                            <span class="text">
                                                {{object5[keyAdditional]}}
                                            </span>
                                        </label>
                                    </li>
                                </template>
                            </ul>
                        </template>
                        <template v-else-if="[0,2].includes(index)">
                            <div>
                              <label class="form-check-inline radio">
                                <input class="form-check-input" type="radio" :value="true" v-model="item.condition" @click="(!!item.condition)?item.condition=null:null" :checked="item.condition === true">
                                <span class="icon"><i class='bx bx-check'></i></span>
                                <span class="text">Yes</span>
                              </label>
                              <label class="form-check-inline radio">
                                <input class="form-check-input" type="radio" :value="false" v-model="item.condition" @click="(!item.condition)?item.condition=null:null" :checked="item.condition === false">
                                <span class="icon"><i class='bx bx-check'></i></span>
                                <span class="text">No</span>
                              </label>
                            </div>
                            <input v-if="item.condition" type="text" v-model="item.additional" class="form-control">
                        </template>
                        <template v-else>
                            <label class="form-check-inline radio">
                                <input class="form-check-input" type="radio" :value="true" v-model="item.condition" @click="(!!item.condition)?item.condition=null:null" :checked="item.condition === true">
                                <span class="icon"><i class='bx bx-check'></i></span>
                                <span class="text">Yes</span>
                            </label>
                            <label class="form-check-inline radio">
                                <input class="form-check-input" type="radio" :value="false" v-model="item.condition" @click="(!item.condition)?item.condition=null:null" :checked="item.condition === false">
                                <span class="icon"><i class='bx bx-check'></i></span>
                                <span class="text">No</span>
                            </label>
                        </template>
                    </div>
                </div>
                <div v-else-if="item.condition === false" class="form-group row">
                    <label class="col-sm-6 col-6 col-form-label">{{item.name}}</label>
                    <div class="col-sm-6 col-6">
                    <template>
                      <label class="form-check-inline radio">
                        <input class="form-check-input" type="radio" :value="true" v-model="item.condition" @click="(!!item.condition)?item.condition=null:null" :checked="item.condition === true">
                        <span class="icon"><i class='bx bx-check'></i></span>
                        <span class="text">Yes</span>
                      </label>
                      <label class="form-check-inline radio">
                        <input class="form-check-input" type="radio" :value="false" v-model="item.condition" @click="(!item.condition)?item.condition=null:null" :checked="item.condition === false">
                        <span class="icon"><i class='bx bx-check'></i></span>
                        <span class="text">No</span>
                      </label>
                    </template>
                    </div>
                </div>
                <div v-else class="form-group row">
                    <label class="col-sm-6 col-6 col-form-label">{{item.name}}</label>
                    <div class="col-sm-6 col-6">
                      <template>
                        <label class="form-check-inline radio">
                          <input class="form-check-input" type="radio" :value="true" v-model="item.condition" :checked="item.condition === true">
                          <span class="icon"><i class='bx bx-check'></i></span>
                          <span class="text">Yes</span>
                        </label>
                        <label class="form-check-inline radio">
                          <input class="form-check-input" type="radio" :value="false" v-model="item.condition" :checked="item.condition === false">
                          <span class="icon"><i class='bx bx-check'></i></span>
                          <span class="text">No</span>
                        </label>
                      </template>
                    </div>
                </div>
            </template>
        </div>
    </div>

</template>

<style scoped>
    .V3 .new-card .card {
        margin-bottom: 0px;
    }

    .cards-edit-page .card-block {
        padding-right: 0;
    }

    .V3 body.V3 .multiselect__select {
        height: 35px;
        right: 0px;
        top: 0px;
    }

    .V3 .card-new-body .card-block {
        padding: 0px;
    }

    .V3 .col-form-label {
        padding: 8px 0;
    }
    @media (max-width: 575.98px) {
        .V3 .col-block .col-form-label{
            padding: 8px 15px;
        }
    }
</style>
