<template>
    <div class="claimlinq-page">
        <div class="page-header">
            <h4>Claims</h4>
            <ol class="breadcrumbs">
                <li class="breadcrumb-item">
                    <router-link :to="'/dashboard'">Home</router-link>
                </li>
                <li class="breadcrumb-item active">View Claims</li>
            </ol>
            <div class="page-header-nav-btn">

                <a @click="clickCreateClaim" class="btn btn-primary btn-submit-quote">
                    <i class='bx bx-plus'></i><span>Create Claim</span>
                </a>
            </div>
        </div>
        <div class="claimlinq-body">
            <tabs ref="claimlinq-table" @changed="tabChanged" cache-lifetime="0" :options="{useUrlFragment:true}">
                <tab name="All" id="claims-all" :class-name="'tab-content-table'">
                    <div class="use-pagination-block-bottom">
                        <b-table
                                responsive
                                hover
                                :items="computedAllClaims"
                                :fields="allFields"
                                :filter="filter"
                                :filter-function="filterForTable"
                                :current-page="claimsAllCurrentPage"
                                @filtered="onAllClaimsFiltered"
                                :per-page="claimsPerPage"
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                                @row-clicked="clickClaimRow"
                        >
                            <template v-slot:head()="data">
                                <div v-if="!data.field.sortable">{{data.label}}</div>
                                <div v-if="data.field.sortable" class="table-sorting">{{data.label}}<i></i></div>
                            </template>
                            <template v-slot:cell(notifications)="data">
                                <notificationOnTable
                                        :notifications="data.item.notifications">
                                </notificationOnTable>
                            </template>
                            <template v-slot:cell(status)="data">
                                {{data.item.status|formatStatus}}
                            </template>
                            <template v-slot:cell(dateReceived)="data">
                                {{data.item.dateReceived|formatDatetime}}
                            </template>
                            <template v-slot:cell(claimType)="data">
                                {{data.item.claimType|formatClaimType}}
                            </template>
                            <template v-slot:cell(repairerNames)="data">
                                <template v-if="data && data.item && data.item.repairerNames && data.item.repairerNames.length > 0">
                                    <div v-html="data.item.repairerNames.filter(el => !!el.trim()).join(';<br>')"></div>
                                </template>
                                <template v-else>{{ data.item.repairerName }}</template>
                            </template>
                        </b-table>
                    </div>
                    <block-pagination
                        :role="'claims'"
                        :arrayOfSomeone="gettersAllClaims"
                        :arrayOfSomeoneFiltered="computedAllClaimsFiltered"
                        :currentPage="claimsAllCurrentPage"
                        @changedCurrentPage="value => $store.commit('claim/setClaimsCurrentPage', value)"
                        :perPage="claimsPerPage"
                        @changedPerPage="value => $store.commit('claim/setClaimsPerPage', value)"
                    >
                    </block-pagination>

                </tab>
                <template slot="nav-item-right">
                    <div class="tabs-nav-right-search">
                        <search-filter
                                type="claims"
                                v-model="searchFilter"
                                :filters="searchFiltersData"
                                :filterValue="filter"
                                @change="onSearchFilterChange"
                                :debug="false"
                                height="41px"
                        />
                    </div>
                    <div class="tabs-nav-right-btn ml-4">
                        <a @click="clickCreateClaim" class="btn btn-primary btn-submit-quote">
                            <i class='bx bx-plus'></i><span>Create Claim</span>
                        </a>
                    </div>
                </template>
            </tabs>
        </div>
    </div>
</template>
<script>
    /*eslint-disable */
    import {mapActions, mapGetters} from 'vuex';
    import notificationOnTable from "@/components/notification-on-table.vue";
    import moment from "moment";
    import SearchFilter, {Filter} from '@/components/search-filter/search-filter.vue';
    import _ from 'lodash';
    import BlockPagination from '../../components/utility/block-pagination';
    import {getClaimsCurrentPage, getClaimsPerPage} from "../../store/modules/claim/getters";

    export default {
        name: 'claims',
        filters: {
            formatStatus(val) {
                if (val == 'OnHold') {
                    return 'On Hold';
                }
                if (val == 'SentToRecoveries') {
                    return 'Sent To Recoveries';
                }
                return val;
            },
            formatClaimType(val) {
                if (val == 'not_set') {
                    return '';
                }
                return val;
            },
            formatClaimDate(val) {
                return moment(val).format('DD-MM-YYYY HH:mm');
            },
        },
        beforeDestroy() {
          clearInterval(this.intervalUpdateClaims);
        },
      data: function () {
            return {
                claimsAllFiltered: [],
                claimsAll: [],
                intervalUpdateClaims: null,
                currentTab: '',
                filter: '',
                searchFilter: '',
                searchFilterObj: {},
                sortBy: 'number',
                sortDesc: true,
                allFields: [
                    {label: "Ref Nbr", key: "number", sortable: true, tdClass: 'clickable number', thClass: ''},
                    {label: "Insured Name", key: "insuredName", sortable: true, tdClass: 'clickable ', thClass: ''},
                    {label: "Other Party Name", key: "otherPartyName", sortable: true, tdClass: 'clickable number', thClass: ''},
                    {label: "Claim Type", key: "claimType", sortable: true, tdClass: 'clickable ', thClass: ''},
                    {label: "Date Created", key: "dateReceived", sortable: true, tdClass: 'clickable ', thClass: ''},
                    {label: "Repairer Name", key: "repairerNames", sortable: true, tdClass: 'clickable', thClass: ''},
                    {label: "Notifications", key: "notifications", sortable: false, tdClass: 'clickable ', thClass: ''},
                    {label: "Claim Status", key: "currentStatusName", sortable: false, tdClass: 'clickable ', thClass: ''},
                ],
            };
        },
        methods: {
            ...mapActions({
                setFilter: 'claim/setFilter',
                setTableSortBy: 'claim/setTableSortBy',
                setTableSortDesc: 'claim/setTableSortDesc',
                actionLoadClaims: 'claim/getClaimsForTableView',
            }),
            setData() {
              this.claimsAll = this.gettersAllClaims;
              this.claimsAllFiltered = this.gettersAllClaims;
            },
            onAllClaimsFiltered(fi) {
              this.claimsAllFiltered = fi;
            },
            filterForTable(row, filter) {
              if (row.number && row.number.toLowerCase().includes(filter.toLowerCase())) {
                return true;
              } else if (row.insuredName && row.insuredName.toLowerCase().includes(filter.toLowerCase())) {
                return true;
              } else if (row.otherPartyName && row.otherPartyName.toLowerCase().includes(filter.toLowerCase())) {
                return true;
              } else if (row.claimType && row.claimType.toLowerCase().includes(filter.toLowerCase())) {
                return true;
              } else if (row.dateReceived && row.dateReceived.toLowerCase().includes(filter.toLowerCase())) {
                return true;
              } else if (row.repairerNames && row.repairerNames.length > 0 && row.repairerNames.join(' ').toLowerCase().includes(filter.toLowerCase())) {
                return true
              } else if (row.repairerName && row.repairerName.toLowerCase().includes(filter.toLowerCase)) {
                return  true;
              } else if (row.currentStatusName && row.currentStatusName.toLowerCase().includes(filter.toLowerCase())) {
                return true;
              } else if (row.rego && row.rego.toLowerCase().includes(filter.toLowerCase())) {
                return true;
              } else if (row.insuredName && row.insuredName.toLowerCase().includes(filter.toLowerCase())) {
                return true;
              } else if (row.rentalVehicleRego && row.rentalVehicleRego.length > 0 && row.rentalVehicleRego.join(' ').toLowerCase().includes(filter.toLowerCase())) {
                return true;
              } else if (row.rentalNumbers && row.rentalNumbers.length > 0 && row.rentalNumbers.join(' ').toLowerCase().includes(filter.toLowerCase())) {
                return true;
              } else if (row.legalRefs && row.legalRefs.length > 0 && row.legalRefs.join(' ').toLowerCase().includes(filter.toLowerCase())) {
                return true;
              } else if (row.legalFirms && row.legalFirms.length > 0 && row.legalFirms.join(' ').toLowerCase().includes(filter.toLowerCase())) {
                return true;
              }
              return false;
            },
            clickCreateClaim() {
                this.$router.push({name: 'ClaimNew'});
            },
            tabChanged(selectedTab) {
                // console.log('Tab changed to:' + selectedTab.tab.name);
                this.currentTab = selectedTab.tab.name;
            },
            changeAllClaimsPage: function () {

            },
            clickClaimRow: function (item, index, event) {
                //this.$router.push({name: 'ClaimView', params: {claimId: item.id}});
                this.$router.push("/claims/" + item.id);
            },
            tableTabChanged: function () {

            },
            cardsAllCurrentPage: function () {

            },

            onAllCardsFiltered: function () {

            },
            onSearchFilterChange(filters) {
                this.searchFilterObj = filters;
                if (this.searchFilterObj && this.searchFilterObj.search) {
                    this.$set(this, 'filter', this.searchFilterObj.search);
                } else {
                    this.$set(this, 'filter', '');
                }
                console.log('onSearchFilterChange', filters);
            }
        },
        mounted() {
            this.$nextTick(() => {
                //console.log("1", this.$refs['claimlinq-table']);
                //console.log("2", this.$refs['claimlinq-table'].activeTabHash);
            });
            if (!this.filter) this.filter = this.gettersFilter;
            if (!this.sortBy) this.sortBy = this.gettersTableSortBy;
            if (!this.sortDesc) this.sortDesc = this.gettersTableSortDesc;

            if (this.gettersFilter) this.filter = this.gettersFilter;
            if (this.gettersTableSortBy != null) this.sortBy = this.gettersTableSortBy;
            if (this.gettersTableSortDesc != null) this.sortDesc = this.gettersTableSortDesc;

            if (this.$route.query.filter) {
                this.filter = this.$route.query.filter;
            }

            this.setData();
            this.actionLoadClaims()
                .then((r)=>{
                  this.setData();
                })

          this.intervalUpdateClaims = setInterval(() => {
            this.claimsAll = this.gettersAllClaims;
          }, 3000);
        },
        computed: {
            ...mapGetters({
                gettersAllClaims: 'claim/getAllClaims',
                gettersFilter: 'claim/getFilter',
                gettersTableSortBy: 'claim/getTableSortBy',
                gettersTableSortDesc: 'claim/getTableSortDesc',
                gettersClaimsPerPage: 'claim/getClaimsPerPage',
                gettersClaimsCurrentPage: 'claim/getClaimsCurrentPage',
            }),
            claimsPerPage() {
                return this.gettersClaimsPerPage;
            },
            claimsAllCurrentPage() {
                return this.gettersClaimsCurrentPage;
            },
            computedAllClaimsFiltered() {
              let vm = this;
              let claims = this.claimsAllFiltered;

              if (vm.searchFilterObj.rego && vm.searchFilterObj.rego != '') {
                claims = _.filter(claims, function (item) {
                  return (item.rego == vm.searchFilterObj.rego);
                });
              }
              if (vm.searchFilterObj.insuredName && vm.searchFilterObj.insuredName != '') {
                claims = _.filter(claims, function (item) {
                  return (item.insuredName == vm.searchFilterObj.insuredName);
                });
              }
              if (vm.searchFilterObj.otherPartyName && vm.searchFilterObj.otherPartyName != '') {
                claims = _.filter(claims, function (item) {
                  return (item.otherPartyName == vm.searchFilterObj.otherPartyName);
                });
              }
              if (vm.searchFilterObj.repairerName && vm.searchFilterObj.repairerName != '') {
                claims = _.filter(claims, function (item) {
                  return (_.includes(item.repairerNames, vm.searchFilterObj.repairerName));
                });
              }
              if (vm.searchFilterObj.rentalRego && vm.searchFilterObj.rentalRego != '') {
                claims = _.filter(claims, function (item) {
                  return (_.includes(item.rentalVehicleRego, vm.searchFilterObj.rentalRego));
                });
              }
              if (vm.searchFilterObj.rentalAgrNbr && vm.searchFilterObj.rentalAgrNbr != '') {
                claims = _.filter(claims, function (item) {
                  return (_.includes(item.rentalNumbers, vm.searchFilterObj.rentalAgrNbr));
                });
              }
              if (vm.searchFilterObj.legalRef && vm.searchFilterObj.legalRef != '') {
                claims = _.filter(claims, function (item) {
                  return (_.includes(item.legalRefs, vm.searchFilterObj.legalRef));
                });
              }
              if (vm.searchFilterObj.legalFirmName && vm.searchFilterObj.legalFirmName != '') {
                claims = _.filter(claims, function (item) {
                  return (_.includes(item.legalFirms, vm.searchFilterObj.legalFirmName));
                });
              }
              if (vm.searchFilterObj.date && vm.searchFilterObj.date.label && vm.searchFilterObj.date.endDate && vm.searchFilterObj.date.startDate) {
                claims = _.filter(claims, function (item) {
                  let d = new Date(item.dateCreated).getTime();
                  let s = new Date(vm.searchFilterObj.date.startDate).getTime();
                  let e = new Date(vm.searchFilterObj.date.endDate).getTime();
                  return d > s && d < e;
                });
              }
              return claims;
            },
            computedAllClaims() {
                let keys = _.keys(this.searchFilterObj);
                console.log('keys', keys);
                let vm = this;
                let claims = this.claimsAll;

                if (vm.searchFilterObj.rego && vm.searchFilterObj.rego != '') {
                    claims = _.filter(claims, function (item) {
                        return (item.rego == vm.searchFilterObj.rego);
                    });
                }
                if (vm.searchFilterObj.insuredName && vm.searchFilterObj.insuredName != '') {
                    claims = _.filter(claims, function (item) {
                        return (item.insuredName == vm.searchFilterObj.insuredName);
                    });
                }
                if (vm.searchFilterObj.otherPartyName && vm.searchFilterObj.otherPartyName != '') {
                    claims = _.filter(claims, function (item) {
                        return (item.otherPartyName == vm.searchFilterObj.otherPartyName);
                    });
                }
                if (vm.searchFilterObj.repairerName && vm.searchFilterObj.repairerName != '') {
                    claims = _.filter(claims, function (item) {
                        return (_.includes(item.repairerNames, vm.searchFilterObj.repairerName));
                    });
                }
                if (vm.searchFilterObj.rentalRego && vm.searchFilterObj.rentalRego != '') {
                  claims = _.filter(claims, function (item) {
                    return (_.includes(item.rentalVehicleRego, vm.searchFilterObj.rentalRego));
                  });
                }
                if (vm.searchFilterObj.rentalAgrNbr && vm.searchFilterObj.rentalAgrNbr != '') {
                    claims = _.filter(claims, function (item) {
                        return (_.includes(item.rentalNumbers, vm.searchFilterObj.rentalAgrNbr));
                    });
                }
                if (vm.searchFilterObj.legalRef && vm.searchFilterObj.legalRef != '') {
                    claims = _.filter(claims, function (item) {
                        return (_.includes(item.legalRefs, vm.searchFilterObj.legalRef));
                    });
                }
                if (vm.searchFilterObj.legalFirmName && vm.searchFilterObj.legalFirmName != '') {
                    claims = _.filter(claims, function (item) {
                        return (_.includes(item.legalFirms, vm.searchFilterObj.legalFirmName));
                    });
                }
                if (vm.searchFilterObj.date && vm.searchFilterObj.date.label && vm.searchFilterObj.date.endDate && vm.searchFilterObj.date.startDate) {
                  claims = _.filter(claims, function (item) {
                    let d = new Date(item.dateCreated).getTime();
                    let s = new Date(vm.searchFilterObj.date.startDate).getTime();
                    let e = new Date(vm.searchFilterObj.date.endDate).getTime();
                    return d > s && d < e;
                  });
                }
                return claims;
            },
            searchFiltersData() {
                // this.searchFiltersData = Filter.generate('claims') // loading type
                if (this.gettersAllClaims.length > 0) {
                    let regos = {};
                    let insuredNames = {};
                    let otherParties = {};
                    let repairerNames = {};
                    let rentalAgrNbrs = {};
                    let rentalVehicleRegos = {};
                    let legalRefs = {};
                    let legalFirmNames = {};
                    this.gettersAllClaims.forEach(claim => {
                        if (claim.rego) {
                            regos[claim.rego] = {label: claim.rego};
                        }
                        if (claim.insuredName !== " " && claim.insuredName) {
                            insuredNames[claim.insuredName] = {label: claim.insuredName};
                        }
                        if (claim.otherPartyName) {
                            otherParties[claim.otherPartyName] = {label: claim.otherPartyName};
                        }
                        if (claim.repairerNames && claim.repairerNames.length > 0) {
                            claim.repairerNames.forEach(repairerName => {
                                repairerNames[repairerName] = {label: repairerName};
                            });
                        }
                        if (claim.rentalNumbers && claim.rentalNumbers.length > 0) {
                            claim.rentalNumbers.forEach(rentalNumber => {
                                rentalAgrNbrs[rentalNumber] = {label: rentalNumber};
                            });
                        }
                        if (claim.rentalVehicleRego && claim.rentalVehicleRego.length > 0) {
                          claim.rentalVehicleRego.forEach(rentalRego => {
                            rentalVehicleRegos[rentalRego] = {label: rentalRego};
                          });
                        }
                        if (claim.legalRefs && claim.legalRefs.length > 0) {
                            claim.legalRefs.forEach(legalRef => {
                                legalRefs[legalRef] = {label: legalRef};
                            });
                        }
                        if (claim.legalFirms && claim.legalFirms.length > 0) {
                            claim.legalFirms.forEach(legalFirmName => {
                                legalFirmNames[legalFirmName] = {label: legalFirmName};
                            });
                        }
                    });
                    regos = Object.values(regos);
                    insuredNames = Object.values(insuredNames);
                    otherParties = Object.values(otherParties);
                    repairerNames = Object.values(repairerNames);
                    rentalAgrNbrs = Object.values(rentalAgrNbrs);
                    rentalVehicleRegos = Object.values(rentalVehicleRegos);
                    legalRefs = Object.values(legalRefs);
                    legalFirmNames = Object.values(legalFirmNames);

                    // Create date template
                    const date = [
                        {
                            id: 'date',
                            label: "Date",
                            resultObjectOnly: true,
                            type: "Date",
                            iconClass: "bx-calendar"
                        }
                    ];

                    // Create searchFilter filters
                    return [
                        {
                            id: 'rego',
                            label: 'Rego',
                            iconClass: 'bx-car',
                            options: [
                                regos,
                                // insuredNames,
                                // otherParties,
                                // repairerNames,
                                // rentalAgrNbrs,
                                   date,
                            ]
                        },
                        {
                            id: 'insuredName',
                            label: 'Insured',
                            iconClass: 'bx-user',
                            options: [
                                insuredNames,
                                date,
                            ]
                        },
                        {
                            id: 'otherPartyName',
                            label: 'Other Party',
                            iconClass: 'bxs-user-circle',
                            options: [
                                otherParties,
                                date,
                            ]
                        },
                        {
                            id: 'repairerName',
                            label: 'Repairer Name',
                            iconClass: 'bxs-user-badge',
                            options: [
                                repairerNames,
                                date,
                            ]
                        },
                        {
                            id: 'rentalAgrNbr',
                            label: 'Rental Agr Nbr',
                            iconClass: 'bx-detail',
                            options: [
                                rentalAgrNbrs,
                                date,
                            ]
                        },
                        {
                          id: 'rentalRego',
                          label: 'Rental Rego',
                          iconClass: 'bx-detail',
                          options: [
                            rentalVehicleRegos,
                            date,
                          ]
                        },
                        {
                            id: 'legalRef',
                            label: 'Legal Ref',
                            iconClass: 'bx-box',
                            options: [
                                legalRefs,
                                date,
                            ]
                        },
                        {
                            id: 'legalFirmName',
                            label: 'Legal Firm',
                            iconClass: 'bx-store',
                            options: [
                                legalFirmNames,
                                date,
                            ]
                        }
                    ];
                } else {
                    return [];
                }
            }
        },
        watch: {
            filter(val) {
                //console.log('updateFilter', val)
                this.setFilter(val);
            },
            sortBy(val) {
                //console.log('updateSortBy', val)
                this.setTableSortBy(val);
            },
            sortDesc(val) {
                //console.log('updateSortDesc', val)
                this.setTableSortDesc(val);
            },
        },
        components: {
            notificationOnTable,
            SearchFilter,
            BlockPagination,
        }
    };
</script>

<style>
    .claimlinq-body td.order-cost {
        padding-right: 25px;
    }

    .V3 .claimlinq-page .b-table td {
        color: #1C1F39;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 16px;
    }

    .V3 .claimlinq-page .b-table td.number {
        font-weight: bold;
    }

    .V3 .btn-submit-quote {
        vertical-align: middle;
    }

    .V3 .btn-submit-quote i {
        font-size: 17px;
        vertical-align: middle;
    }

    .V3 .btn-submit-quote span {
        vertical-align: middle;
    }

</style>
