<template>
    <div class="dashboard-page">
        <div class="page-header">
            <h4>Dashboard</h4>
            <ol class="breadcrumbs">
                <li class="breadcrumb-item active">Dashboard</li>
            </ol>
        </div>
        <div class="white-bg row dashboard-header-border">
            <div class="col-12 col-sm-12 col-md-4 dashboard-col">
                <div class="card px-1">
                    <div class="card-header  uppercase dashboard-header">
                        <strong>{{headerForTask}}</strong>
                    </div>
                    <div v-if="(tasks && tasks.length > 0) || (comments && comments.length > 0)" class="card-block dashboard-body">
                        <tabs :options="{ useUrlFragment: false, isOrdered:true }" @changed="changedTab" ref="dashboard-tasks-tabs">
                            <tab name="Tasks" id="dashboard-tasks-tab-tasks">
                                <div v-if="(tasks && tasks.length > 0)">
                                    <task-summary v-for="(task, ti) in computedTasks" v-bind:key="ti" v-show="ti < showCountTasks" :task="task"></task-summary>
                                    <div>
                                        <b-dropdown ref="sort-dropdown" class="mr-0 show-count-dropdown pull-right" right>
                                            <template v-slot:button-content>
                                                Show {{ showCountTasks }} <span class="bx bxs-chevron-down"></span>
                                            </template>
                                            <b-dropdown-item @click="showCountTasks = 5" >Show 5</b-dropdown-item>
                                            <b-dropdown-item @click="showCountTasks = 10">Show 10</b-dropdown-item>
                                            <b-dropdown-item @click="showCountTasks = 20">Show 20</b-dropdown-item>
                                            <b-dropdown-item @click="showCountTasks = 50">Show 50</b-dropdown-item>
                                        </b-dropdown>
                                    </div>
                                </div>
                                <div v-else-if="!showOldTasks" style="text-align: center;">
                                    No new tasks today
                                    <div style="margin-top: 25px;">
                                        <button class="btn btn-primary" @click="showOldTasks = true">View Previous</button>
                                    </div>
                                </div>
                            </tab>
                            <tab name="Comments" id="dashboard-tasks-tab-comments">
                                <div v-if="(comments && comments.length > 0)">
                                    <comment-summary v-for="(comment, ci) in computedComments" v-bind:key="ci" v-show="ci < showCountTasks" :comment="comment"></comment-summary>
                                    <div>
                                        <b-dropdown ref="sort-dropdown" class="mr-0 show-count-dropdown pull-right" right>
                                            <template v-slot:button-content>
                                                Show {{ showCountTasks }} <span class="bx bxs-chevron-down"></span>
                                            </template>
                                            <b-dropdown-item @click="showCountTasks = 5" >Show 5</b-dropdown-item>
                                            <b-dropdown-item @click="showCountTasks = 10">Show 10</b-dropdown-item>
                                            <b-dropdown-item @click="showCountTasks = 20">Show 20</b-dropdown-item>
                                            <b-dropdown-item @click="showCountTasks = 50">Show 50</b-dropdown-item>
                                        </b-dropdown>
                                    </div>
                                </div>
                                <div v-else-if="!showOldComments">
                                </div>
                            </tab>
                            <template slot="nav-item-right">
                                <div class="tabs-nav-right-search">
                                    <b-form-input v-model="filter"
                                                  type="text"
                                                  class="search-input"
                                                  placeholder="Search"
                                                  debounce="100"/>
                                </div>
                            </template>
                        </tabs>
                    </div>
                    <div v-else-if="!showOldComments && !showOldTasks" class="card-block dashboard-body  empty-default">
                        <div>
                            <div class="mb-3">
                                No new tasks and comments today
                            </div>
                            <div>
                                <button class="btn btn-primary" @click="showOldTasks = showOldComments = true">View Previous</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-4 dashboard-col">
                <div class="card px-1">
                    <div class="card-header  uppercase dashboard-header">
                        <strong>{{ headerForEmail }}</strong>
                    </div>
                    <div v-if="(emails && emails.length > 0)" class="card-block dashboard-body">
                        <email-summary v-for="(email, ei) in emails" v-bind:key="ei" :email="email" v-show="ei < showCountEmails" />
                        <div>
                            <b-dropdown ref="sort-dropdown" class="mr-0 show-count-dropdown pull-right" right>
                                <template v-slot:button-content>
                                    Show {{ showCountEmails }} <span class="bx bxs-chevron-down"></span>
                                </template>
                                <b-dropdown-item @click="showCountEmails = 5" >Show 5</b-dropdown-item>
                                <b-dropdown-item @click="showCountEmails = 10">Show 10</b-dropdown-item>
                                <b-dropdown-item @click="showCountEmails = 20">Show 20</b-dropdown-item>
                                <b-dropdown-item @click="showCountEmails = 50">Show 50</b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </div>
                    <div v-else-if="!showOldEmails" class="card-block dashboard-body empty-default">
                        <div>
                            <div class="mb-3">
                                No new emails today
                            </div>
                            <div>
                                <button class="btn btn-primary" @click="showOldEmails = true">View Previous</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-4 "></div>
        </div>
        <div class="widget-panel white-bg mb-0 ">
        </div>
    </div>
</template>

<script>
    /*eslint-disable */
    import Axios from "axios";
    import TaskSummary from './task-summary';
    import CommentSummary from './comment-summary';
    import EmailSummary from './email-summary';
    import {mapGetters} from "vuex";
    import _ from "lodash" ;

    export default {
        name: "task-dashboard",
        components: {
            TaskSummary,
            CommentSummary,
            EmailSummary,
        },
        data() {
            return {
                filter: null,
                filterEmail: null,
                comments: [],
                tasks: [],
                emails: [],
                showTasks: 5,
                showComments: 5,
                showEmails: 5,
                activeTab: '',
            };
        },
        computed: {
            ...mapGetters({
                isNeedUpdateDashboard: "updater/isNeedUpdateDashboard",
                getShowCountTasks: "dashboard/getShowCountTasks",
                getShowCountEmails: "dashboard/getShowCountEmails",
                getShowOldTasks: "dashboard/getShowOldTasks",
                getShowOldComments: "dashboard/getShowOldComments",
                getShowOldEmails: "dashboard/getShowOldEmails",
            }),
            computedTasks() {
                if (!this.filter) {
                    return this.tasks;
                }
                let data = [];
                let vm = this;
                data = _.filter(this.tasks, function (task) {
                    return (vm.isFilteredElement(task.notesText, vm.filter)
                        || vm.isFilteredElement(task.name, vm.filter)
                        || vm.isFilteredElement(task.claimNumber, vm.filter)
                        || vm.isFilteredElement(task.assessmentNumber, vm.filter)
                        || vm.isFilteredElement(task.createdById, vm.filter));
                });
                return data;
            },
            computedComments() {
                if (!this.filter) {
                    return this.comments;
                }
                let data = [];
                let vm = this;
                data = _.filter(this.comments, function (comment) {
                    return (vm.isFilteredElement(comment.text, vm.filter)
                        || vm.isFilteredElement(comment.task.name, vm.filter)
                        || vm.isFilteredElement(comment.task.claimNumber, vm.filter)
                        || vm.isFilteredElement(comment.task.assessmentNumber, vm.filter)
                        || vm.isFilteredElement(comment.createdById, vm.filter));
                });
                return data;
            },
            computedEmails() {
                if (!this.filterEmail) {
                    return this.emails;
                }
                let data = [];
                let vm = this;
                data = _.filter(this.emails, function (email) {
                    return (vm.isFilteredElement(email.from, vm.filterEmail)
                        || vm.isFilteredElement(email.recipient, vm.filterEmail)
                        || vm.isFilteredElement(email.subject, vm.filterEmail));
                });
                return data;
            },
            showCountTasks: {
              get() {
                return this.getShowCountTasks;
              },
              set(newVal) {
                this.$store.dispatch('dashboard/setShowCountTasks', newVal);
              }
            },
            showCountEmails: {
              get() {
                return this.getShowCountEmails;
              },
              set(newVal) {
                this.$store.dispatch('dashboard/setShowCountEmails', newVal);
              }
            },
            showOldTasks: {
                get() {
                    return this.getShowOldTasks;
                },
                set(newVal) {
                    this.$store.dispatch('dashboard/setShowOldTasks', newVal);
                    this.loadData();
                }
            },
            showOldComments: {
                get() {
                    return this.getShowOldComments;
                },
                set(newVal) {
                    this.$store.dispatch('dashboard/setShowOldComments', newVal);
                    this.loadData();
                }
            },
            showOldEmails: {
                get() {
                    return this.getShowOldEmails;
                },
                set(newVal) {
                    this.$store.dispatch('dashboard/setShowOldEmails', newVal);
                    this.loadData();
                }
            },
            headerForTask() {
                if (this.activeTab) {
                    console.log('2', this.activeTab.computedId);
                }
                if (this.activeTab && this.activeTab.computedId && this.activeTab.computedId == "dashboard-tasks-tab-comments") {
                    if (this.getShowOldComments) {
                        return 'Previous Comments';
                    } else {
                        return 'Today\'s New Comments';
                    }
                }
                if (this.getShowOldTasks) {
                    return 'Previous Tasks Assigned';
                } else {
                    return 'Today\'s New Tasks Assigned';
                }
            },
            headerForEmail() {
                if (this.getShowOldEmails) {
                    return 'Previous Incoming Emails';
                }
                return 'Today\'s Incoming Emails';
            },
            showMoreTasksCount() {
                let d = this.tasks.length - this.showTasks;
                if (d > 5) {
                    return 5;
                }
                if (d < 0) {
                    return 0;
                }
                return d;
            },
            showMoreCommentsCount() {
                let d = this.comments.length - this.showComments;
                if (d > 5) {
                    return 5;
                }
                if (d < 0) {
                    return 0;
                }
                return d;
            },
            showMoreEmailsCount() {
                let d = this.emails.length - this.showEmails;
                if (d > 5) {
                    return 5;
                }
                if (d < 0) {
                    return 0;
                }
                return d;
            }
        },
        mounted() {
            this.loadData();
        },
        methods: {
            isFilteredElement(filterText, query) {
                if (!query) {
                    return true;
                }
                if (filterText && filterText != '') {
                    return (new RegExp(query, 'iug').test(filterText));
                }
                return false;
            },
            loadData() {
                NProgress.start();
                Axios.post('/ir/dashboard', {
                    showCount: this.showCount,
                    showOldTasks: this.showOldTasks,
                    showOldComments: this.showOldComments,
                    showOldEmails: this.showOldEmails,
                }).then(response => {
                    //console.log(response.data);
                    if (response.data._status) {
                        this.tasks = response.data.tasks;
                        this.comments = response.data.comments;
                        this.emails = response.data.emails;
                    }
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    NProgress.done();
                });
            },
            changedTab(tab) {
                this.activeTab = tab.tab;
            },
            showMoreTasks() {
                this.showTasks += 5;
            },
            showMoreComments() {
                this.showComments += 5;
            },
            showMoreEmails() {
                this.showEmails += 5;
            },
        },
        watch: {
            isNeedUpdateDashboard(newVal) {
                if (!newVal) return;
                this.loadData();
                this.$store.dispatch('updater/resetDashboard');
            }
        },
    };
</script>

<style scoped>
    .uppercase {
        text-transform: uppercase;
    }

    .dashboard-header-border {
        border-top: 10px solid #EFEFF1;
    }

    .dashboard-col {
        padding: 0;
    }

    @media (min-width: 576px) {
        .dashboard-col:first-child {
            margin-left: 15px;
        }

        .dashboard-col:last-child {
            margin-right: 15px;
        }
    }

    @media (max-width: 575.98px) {
        .dashboard-col ::v-deep .tab-header.rs-btn-group {
            /* display: none; */
        }
    }

    .dashboard-col .dashboard-header {
        background: #FFFFFF;
        padding-left: 15px;
        padding-right: 15px;
    }

    .dashboard-col .dashboard-body {
        padding-left: 15px;
        padding-right: 15px;
        background-color: rgba(241, 241, 247, 0.618);
        min-height: 400px;
    }

    .dashboard-col ::v-deep .tabs-component {
        position: inherit;
        margin-bottom: 0.75rem;
    }

    .dashboard-col ::v-deep .tabs-component .tab-header {
        height: 50px;
        flex-flow: row nowrap;
    }

    .dashboard-col ::v-deep .nav-tabs-2 .nav-link {
        height: 39px;
    }

    .dashboard-col ::v-deep .dashboard-row-element {
        background-color: #FFFFFF;
        /* background-color: #efefee; */ /* for muted */
    }

    .dashboard-col .dashboard-body ::v-deep .tab-content {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .empty-default {
        text-align: center;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
    }

    /* Dashboard Search Input Style */
    .dashboard-body ::v-deep .tabs-component {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .dashboard-body ::v-deep .tabs-component::after {
        content: unset;
    }

    .dashboard-body ::v-deep .tabs-component .nav-tabs {
        /* flex: 0 0 auto; */
        /* background-color: yellow; */
    }

    .dashboard-body ::v-deep div.tab-header.rs-btn-group {
        flex: 1 1 290px;
    }

    .dashboard-body ::v-deep div.tabs-nav-right-search {
        min-width: unset !important;
        width: 100%;
    }

    .dashboard-body ::v-deep div.tabs-nav-right-search {
        height: 50px;
        display: flex;
        align-items: center;
    }
    /* End - Dashboard Search Input Style */

</style>
<style>
    .show-count-dropdown .btn {
        border: 1px solid #00B050 !important;
        background-color: #FFFFFF !important;
        color: #333333 !important;
    }
</style>
