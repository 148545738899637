<template>
    <div class="px-0 py-0">
        <div class="form-group row">
            <label class="col-sm-4 col-4 col-form-label">Location</label>
            <div class="col-sm-8 col-8">
                <input type="text" v-model="address.location" class="form-control" :readonly="isReadonly">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-4 col-4 col-form-label">Lot / Unit / Lvl</label>
            <div class="col-sm-8 col-8">
                <input type="text" v-model="address.levelUnitLot" class="form-control" :readonly="isReadonly">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-4 col-4 col-form-label">Street Name / Nbr</label>
            <div class="col-sm-8 col-8">
                <input type="text" v-model="address.streetName" class="form-control" :readonly="isReadonly">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-4 col-4 col-form-label">Suburb</label>
            <div class="col-sm-8 col-8">
                <input type="text" v-model="address.suburb" class="form-control" :readonly="isReadonly">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-4 col-4 col-form-label">State</label>
            <div class="col-sm-8 col-8">
                <multiselect
                        v-model="aState"
                        :options="addressStates"
                        track-by="value"
                        label="value"
                        :showLabels="false"
                        :option-height="29"
                        :max-height="203"
                        :close-on-select="true"
                        :allow-empty="false"
                        placeholder="Select state"
                        :disabled="isReadonly"
                />
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-4 col-4 col-form-label">Postcode</label>
            <div class="col-sm-8 col-8">
                <input type="text" v-model="address.postcode" class="form-control" :readonly="isReadonly">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-4 col-4 col-form-label">Country</label>
            <div class="col-sm-8 col-8">
                <multiselect
                        v-model="country"
                        :options="addressCountries"
                        track-by="value"
                        label="value"
                        :showLabels="false"
                        :option-height="29"
                        :max-height="203"
                        :close-on-select="true"
                        :allow-empty="false"
                        placeholder="Select country"
                        :disabled="isReadonly"
                />
            </div>
        </div>
    </div>
</template>
<script>
    /* eslint-disable */
    import {mapGetters} from 'vuex';
    import multiselect from 'vue-multiselect';
    import GoogleLocation from "@/components/utility/google-location";

    export default {
        name: 'block-address',
        props: {
            address: {
                type: Object,
                default: () => ({
                    location: '',
                    levelUnitLot: '',
                    streetName: '',
                    suburb: '',
                    state: '',
                    postcode: '',
                    country: 'AU',
                })
            },
            isReadonly:{
                type: Boolean,
                default: false,
            }
        },
        data: function () {
            return {};
        },
        computed: {
            ...mapGetters({
                addressStates: 'addressStates',
                addressCountries: 'addressCountries',
            }),
            country: {
                get() {
                    return _.filter(this.addressCountries, item => {
                        return item.key === this.address.country;
                    });
                },
                set(value) {
                    this.address.country = value.key;
                }
            },
            aState: {
                get() {
                    return _.filter(this.addressStates, item => {
                        return item.key === this.address.state;
                    });
                },
                set(value) {
                    this.address.state = value.key;
                }
            }
        },
        components: {
            multiselect,
            GoogleLocation
        }
    };
</script>
<style scoped>
    .col-form-label {
        padding: 8px 0;
    }
    @media (max-width: 575.98px) {
        .V3 .col-block .col-form-label{
            padding: 8px 15px;
        }
    }
</style>