<template>
    <div class="dashboard-panel card" ref="container" v-bind:style="{'max-width':width || '100%'}">
        <div class="card-body">
            <div class="card-title">
                <h4 class="title-text">This Month</h4>
                <div class="search grid">
                </div>
            </div>
            <div class="cards-list">
                <div class="card-item">
                    <div class="header-item">
                        Repairs Assessed
                        <i class="bx bx-link-external"></i>
                    </div>
                    <div class="body-item">
                        <div class="list-values">
                            <div class="list-values-header">
                                <span class="numb">{{assessments.processing.count}}</span>
                                Assessments
                            </div>
                            <div v-if="assessments.processing.sum>0" class="list-values-subheader">
                                <span class="numb">{{assessments.processing.sum|formatMoney}}</span> Repair value

                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-item">
                    <div class="header-item">
                        Rentals In Progress
                        <i class="bx bx-flag"></i>
                    </div>
                    <div class="body-item">
                        <div class="list-values">
                            <div class="list-values-header">
                                <router-link :to="{name:'Claims', query: {filter: '#RentalInProgress'}}">
                                    <span class="numb">{{rentals.count}}</span>
                                Rentals
                                </router-link>
                            </div>
                            <div v-if="rentals.sum>0" class="list-values-subheader">
                                <span class="numb">{{rentals.sum|formatMoney}}</span> Rental value

                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-item">
                    <div class="header-item">
                        Settled Claims
                        <i class="bx bxs-truck" style="color:#00B050"></i>
                    </div>
                    <div class="body-item">
                        <div class="list-values">
                            <div class="list-values-header">
                                <span class="numb">{{claims.settled.count}}</span>
                                settled claims
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-item">
                    <div class="header-item">
                        New Assessments

                        <i class="bx bx-buildings"></i>

                    </div>
                    <div class="body-item">
                        <div class="list-values">
                            <div class="list-values-header">
                                <span class="numb">{{assessments.new.count}}</span> Assessments
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <h4 class="title-text chart-name">
                Settlements -
                <small>{{ this.showChartBy.label }}</small>

                <b-dropdown right text="Options" variant="link" toggle-class="text-decoration-none" no-caret>
                    <template v-slot:button-content>
                        <i class="bx bx-chevron-down text-black"></i>
                    </template>
                    <b-dropdown-item @click="showByMonth">
                        by Month
                    </b-dropdown-item>
                    <!--                    <b-dropdown-item @click="showByWeek">-->
                    <!--                        by Week-->
                    <!--                    </b-dropdown-item>-->
                </b-dropdown>

            </h4>
            <div id="chart-area"></div>
        </div>
    </div>
</template>


<script>
    /* eslint-disable */

    import chart from "tui-chart";
    import "tui-chart/dist/tui-chart.css";
    // import moment from "moment";
    import accounting from "accounting";

    export default {
        name: "WidgetSettlementsTab",
        props: {
            width: String,
            height: String,
            claims: {
                type: Object,
                default() {
                    return {
                        settled: {
                            count: 0,
                            sum: 0,
                        },
                    };
                }
            },
            assessments: {
                type: Object,
                default() {
                    return {
                        new: {
                            count: 0,
                            sum: 0,
                        },
                        processing: {
                            count: 0,
                            sum: 0,
                        },
                    };
                }
            },
            months: Object,
            rentals: {
                type: Object,
                default() {
                    return {
                        count: 0,
                        sum: 0,
                    };
                }
            },
        },
        components: {},
        mounted() {
            // this is bad
            const menuBtn = document.querySelector(".nav-link.toggle-button");
            if (menuBtn) {
                menuBtn.addEventListener("click", () => {
                    this.resizeChart();
                });
            }

            window.addEventListener("orientationchange", () => {
                this.resizeChart();
            });

        },
        data() {
            return {
                testData: {
                    credits: {},
                    categories: [],
                    series: [
                        {
                            name: "Orders",
                            data: []
                        }
                    ]
                },
                yAxisMax: 80000,
                ordersOrderedCount: 0,
                ordersOnBackOrderCount: 0,
                ordersOnRouteCount: 0,
                ordersDeliveredCount: 0,
                space: 16,
                ordersChart: null,
                showChartBy: {
                    type: 'month',
                    label: 'by Month'
                },
              chartData: {
                month: {
                  credits: {},
                  categories: [],
                  series: [
                    {
                      name: "Test name",
                      data: []
                    }
                  ],
                  maxY: 100,
                },
                week: {
                  credits: {},
                  categories: [],
                  series: [
                    {
                      name: "Orders",
                      data: [5000, 7000]
                    }
                  ],
                  maxY: 100,
                }
              },
              chartData2: {
                month: {
                  credits: {},
                  categories: [
                      'One',
                      'Two',
                      'Therr'
                  ],
                  series: [
                    {
                      name: "Test name",
                      data: [20000,40000,30000]
                    }
                  ],
                  maxY: 100,
                },
                week: {
                  credits: {},
                  categories: [],
                  series: [
                    {
                      name: "Orders",
                      data: [5000, 7000]
                    }
                  ],
                  maxY: 100,
                }
              }
            };
        },
        methods: {
          initChart(){
            this.setChartDataByKey(this.months, 'month');
            this.createChart();
            this.showByMonth();
          },
            openRouteWithHash(hash) {
                this.$router.push({name: 'SuppliersPartsOrders', hash: '#' + hash});
            },
            setShowChartBy(type) {
                let result = {
                    type: 'month',
                    label: 'by Month'
                };
                if (type === 'week' && !_.isEmpty(this.chartData.week.categories)) {
                    result = {
                        type: 'week',
                        label: 'by Week'
                    };
                }
                this.createChart(result.type);
                this.showChartBy = result;
                this.resizeChart();
            },
            setChartDataByKey(chartData = {}, chartKey = '') {
                let categories = [],
                    data = [];
                _.forEach(chartData, (value, key) => {
                    categories.push(key);
                    data.push(value);
                });
                //this.chartData[chartKey]. = chartData[chartKey].credits;
                this.chartData[chartKey].categories = categories;
                this.chartData[chartKey].series[0].data = data;
            },
            resizeChart() {
                if (!this.$refs.container) return;
                setTimeout(() => {
                    this.ordersChart.resize({
                        width: this.$refs.container.clientWidth - this.space,
                        height: this.height
                    });
                }, 100);
            },
            createChart(type = 'month') {
                const container = document.getElementById("chart-area");

                let data = this.chartData2.month;
                //let data = (type === 'month') ? this.months : {};

                //let months = _.map(this.months, (item, key) => {
                //    return {
                //        date: key,
                //        value: item
                //    };
                //});

                const theme = {
                    chart: {
                        fontFamily: "Nunito Sans",
                        fontSize: "16px"
                    },
                    series: {
                        colors: ["#FFCFB1"],
                        width: "50px"
                    },
                    xAxis: {
                        tickColor: "rgba(27,30,56,0.1)",
                        label: {
                            fontSize: 14,
                            fontFamily: "Nunito Sans",
                            fontWeight: "bold",
                            color: "#1C1F39"
                        }
                    },
                    yAxis: {
                        tickColor: "rgba(27,30,56,0.1)",
                        label: {
                            fontSize: 14,
                            fontFamily: "Nunito Sans",
                            fontWeight: "bold",
                            color: "#1C1F39"
                        }
                    },
                    plot: {
                        lineColor: "rgba(27,30,56,0.1)",
                        background: "#EFEFEF"
                    }
                };

              // console.log('chartData', data);
              //
              // this.ordersChart = chart.columnChart(container, data, options);

              const options = {
                chartExportMenu: {
                  visible: false
                },
                chart: {
                  // title: 'Average Temperature',
                  width: parseInt(this.$refs.container.clientWidth) - parseInt(this.space),
                  height: parseInt(this.height) || 304,
                  format: "1,000"
                },
                xAxis: {
                  title: "&nbsp;",
                  pointOnColumn: true
                },
                yAxis: {
                  title: "",
                  prefix: "$",
                  // min: 0,
                  // max: 80000,
                },
                tooltip: {
                  offsetX: 1,
                  offsetY: -15,
                  template: (category, item) => {
                    let creditData = _.find(this.months, month => {
                       return month.month_label === category;
                    });

                    console.log(creditData)

                    let head =
                        '<div class="tt-title">' + creditData.month_name + "</div>",
                        body =
                            `<div  class="tt-body">
                            <ul>
                            <li>Claims Approved <span class="tt-value">${creditData.claims_approved}</span></li>
                            <li>Claimed - Repair <span class="tt-value">${accounting.formatMoney(creditData.repair_sum)}</span></li>
                            <li>Claimed - Rental <span class="tt-value">${accounting.formatMoney(creditData.rentals_sum)}</span></li>
                            <li>Settled <span class="tt-value"><b>${accounting.formatMoney(creditData.settled)}</b></span></li>
                            <li>Avg Recovery Time <span class="tt-value">${creditData.avg_recovery} day(s)</span></li>
                            </ul>
                            </div>`;
                    return (
                        '<div class="tooltip-template">' +
                        head +
                        body +
                        '<div class="point"></div></div>'
                    );
                  }
                },
                legend: {
                  visible: false
                },
                series: {
                  barWidth: 30
                }
              };

              chart.registerTheme("myTheme", theme);
              options.theme = "myTheme";

              container.innerHTML = '';

              this.ordersChart = chart.columnChart(container, this.computedChartData, options);

              window.addEventListener("resize", () => {
                  this.resizeChart();
              });
            },
            showByMonth() {
                this.setShowChartBy('month');
            },
            showByWeek() {
                this.setShowChartBy('week');
            },
        },
        computed: {
          computedChartData(){
            let categories = [];
            let data = [];
            for(let d in this.months){
              let m = this.months[d];
              categories.push(m.month_label);
              data.push(m.settled)
            }

            return {
              credits: {},
              categories,
              series: [
                {
                  name: "Test name",
                  data
                }
              ],
              maxY: 100,
            };
          }
        },
        watch: {
          months(){
            setTimeout(this.initChart, 200)
          },
        },
    };
</script>

<style scoped>
    @import url(../../../../styles/dashboard/style.css);

    .chart-name {
        margin-top: 20px;
        font-size: 18px;
        font-weight: bold;
        line-height: 24px;
        display: flex;
        align-items: center;
    }

    .chart-name i {
        font-size: 32px;
        cursor: pointer;
    }

    .chart-name small {
        line-height: 22px;
        font-size: 16px;
        font-weight: 600;
        margin-left: 5px;
    }

    .list-values .clickable:hover {
        color: #000000;
    }

    .card-item .body-item {

    }
</style>

