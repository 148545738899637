<script>
    /* eslint-disable */
    import AdvancedSearch from "@/components/utility/advanced-search";
    import Axios from "axios";

    export default {
        name: 'block-accident-towing',
        props: {
            towing: {
                type: Object,
                default: function () {
                    return {
                        towingCompany: '',
                        towingCompanyId: 0,
                        pickUpDate: null,
                        towingRequired: null,
                        dropOfDate: null,
                        dropOfLocation: '',
                    };
                }
            },

        },
        data: function () {
            return {
                towingCompanyName: '',
                towDrivers: [],
            };
        },
        computed: {
            computedTowingCompany: {
                get() {
                    return this.towingCompanyName;
                },
                set(val) {
                    console.log('computedTowingCompany', val);
                    this.towingCompanyName = val;
                },
            }

        },
        methods: {
            addTowingCompany(name) {
                Axios.post('/ir/tow-drivers', {name: name})
                    .then(response => {
                        if (response && response.data && response.data._status) {
                            this.loadTowDrivers();
                            this.towing.towingCompanyId = response.data.id;
                        } else {
                        }
                    })
                    .catch(e => console.error(e));

            },
            onTowingCompanySelect(val) {
                if (val) {
                    this.towing.towingCompanyId = val.id;
                } else {
                    this.addTowingCompany(this.towingCompanyName);
                }
            },
            loadTowDrivers() {
                NProgress.start();
                Axios.get('/ir/tow-drivers/short')
                    .then(response => {
                        if (response && response.data && response.data._status) {
                            this.towDrivers = response.data.data;
                        }
                    })
                    .catch(e => console.error(e))
                    .finally(() => {
                        NProgress.done();
                    });
            },
            changeTowingCompanyName(id){
                let tc = _.find(this.towDrivers, item => {
                    return Number(item.id) === Number(id);
                });
                if (tc && tc.name) {
                    this.towingCompanyName = tc.name;
                }
                console.log('towing.towingCompanyId', id);
            }
        },
        watch: {
            'towing.towingCompanyId': function (newVal) {
                this.changeTowingCompanyName(newVal);
            },
            'towDrivers': function(){
                this.changeTowingCompanyName(this.towing.towingCompanyId);
            },
        },
        components: {
            AdvancedSearch,
        },
        mounted(){
            this.loadTowDrivers();
        }

    };
</script>
<template>
    <div class="card">
        <div class="card-header bg-navyblue">
            <strong>Towing</strong>
        </div>
        <div class="card-block bg-white">
            <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Towing Required</label>
                <div class="col-sm-8 col-8">
                    <label class="form-check-inline radio">
                        <input v-model="towing.towingRequired" class="form-check-input" type="radio" value="true"  @click="(!!towing.towingRequired)?towing.towingRequired=null:null" @change="towing.towingRequired=true">
                        <span class="icon"><i class='bx bx-check'></i></span>
                        <span class="text">Yes</span>

                    </label>
                    <label class="form-check-inline radio">
                        <input v-model="towing.towingRequired" class="form-check-input" type="radio" value="false"  @click="(!towing.towingRequired)?towing.towingRequired=null:null" @change="towing.towingRequired=false">
                        <span class="icon"><i class='bx bx-check'></i></span>
                        <span class="text">No</span>
                    </label>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Towing Company</label>
                <div class="col-sm-8 col-8">
                    <advanced-search ref="towing.towingCompany"
                                     searchLabel="Towing Company"
                                     v-model="computedTowingCompany"
                                     :searchData="towDrivers"
                                     searchKey="name"
                                     @onSelect="onTowingCompanySelect"
                                     :placeholderText="'Towing Company'"
                    >
                    </advanced-search>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Pick up Date</label>
                <div class="col-sm-8 col-8">
                    <b-form-datepicker
                            v-model="towing.pickUpDate"
                            class="mb-2 "
                            locale="en-GB"
                            :date-format-options="{  day: '2-digit', month: '2-digit', year: 'numeric'}"
                    ></b-form-datepicker>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Drop of Date</label>
                <div class="col-sm-8 col-8">
                    <b-form-datepicker
                            v-model="towing.dropOfDate"
                            class="mb-2 "
                            locale="en-GB"
                            :date-format-options="{  day: '2-digit', month: '2-digit', year: 'numeric'}"

                    ></b-form-datepicker>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Drop of Location</label>
                <div class="col-sm-8 col-8">
                    <input type="text" v-model="towing.dropOfLocation" class="form-control">
                </div>
            </div>
        </div>
    </div>

</template>

<style scoped>
    .V3 .new-card .card {
        margin-bottom: 0px;
    }

    .cards-edit-page .card-block {
        padding-right: 0;
    }

    .V3 body.V3 .multiselect__select {
        height: 35px;
        right: 0px;
        top: 0px;
    }

    .V3 .card-new-body .card-block {
        padding: 0px;
    }

    .V3 .col-form-label {
        padding: 8px 0;
    }
    @media (max-width: 575.98px) {
        .V3 .col-block .col-form-label{
            padding: 8px 15px;
        }
    }
</style>
