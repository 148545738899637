<template>
    <div class="dashboard-panel card" v-bind:style="{'max-width':width || '100%'}">
        <div class="card-body">
            <div class="card-title">
                <h4 class="title-text">Assessments</h4>
                <div class="search grid">
                </div>
            </div>
            <div class="cards-list">
                <router-link :to="{name:'Assessments', hash: '#assessments-new'}">
                    <div class="card-item">
                        <div class="header-item">
                            New
                            <i class="bx bx-link-external clickable"></i>
                        </div>
                        <div class="body-item">
                            <div class="list-values ">
                                <div class="list-values-header claimlinq-bg clickable">
                                    <span class="numb">{{countNewAssessments}}</span> New
                                </div>
                            </div>
                        </div>
                    </div>
                </router-link>
                <router-link :to="{name:'Assessments', hash: '#assessments-processing'}">
                    <div class="card-item">
                        <div class="header-item">
                          Processing
                            <i class="bx bx-link-external clickable" style="color:#00B050"></i>
                        </div>
                        <div class="body-item">
                            <div class="list-values ">
                                <div class="list-values-header claimlinq-bg clickable">
                                    <span class="numb">{{countProcessingAssessments}}</span> Processing
                                </div>
                            </div>
                        </div>
                    </div>
                </router-link>
                <router-link :to="{name:'Assessments', hash: '#assessments-wrecoveries'}">
                    <div class="card-item">
                        <div class="header-item">
                            W/Recoveries
                            <i class="bx bx-dollar-circle clickable" style="color:#00B050"></i>
                        </div>
                        <div class="body-item">
                            <div class="list-values ">
                                <div class="list-values-header claimlinq-bg clickable">
                                    <span class="numb">{{countWRecoveriesAssessments}}</span> W/Recoveries
                                </div>
                            </div>
                        </div>
                    </div>
                </router-link>
                <router-link :to="{name:'Assessments', hash: '#assessments-on-hold'}">
                    <div class="card-item">
                        <div class="header-item">
                            On-Hold
                            <i class='bx bx-stop-circle' style="color:red"></i>
                        </div>
                        <div class="body-item">
                            <div class="list-values ">
                                <div class="list-values-header claimlinq-bg clickable">
                                    <span class="numb">{{countOnHoldAssessments}}</span> On-Hold
                                </div>
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<style scoped>
    @import url(../../../styles/dashboard/style.css);

    .list-values .clickable:hover {
        color: #000000;
    }

    .card-item .body-item {
        min-height: 100px;
    }
</style>

<script>
    import {mapGetters} from 'vuex';
    // import {
    //   countCompletedAssessments,
    //   countNewAssessments, countOnHoldAssessments,
    //   countProcessingAssessments,
    //   countWRecoveriesAssessments
    // } from "@/store/modules/assessment/getters";

    export default {
        name: "WidgetAssessments",
        props: {
            width: String,
            assessments: {
                type: Object,
                default() {
                    return {
                        new: {
                            count: 0,
                        },
                        processing: {
                            count: 0,
                        },
                        withRecoveries: {
                            count: 0,
                        },
                        onHold: {
                            count: 0,
                        },
                    };
                }
            },
        },
        methods: {},
        computed: {
            ...mapGetters({
              countNewAssessments: "assessment/countNewAssessments",
              countWRecoveriesAssessments: "assessment/countWRecoveriesAssessments",
              countProcessingAssessments: "assessment/countProcessingAssessments",
              countOnHoldAssessments: "assessment/countOnHoldAssessments",
              countCompletedAssessments: "assessment/countCompletedAssessments",
            }),
        },
        components: {}
    };
</script>
