<template>
    <div class="dashboard-panel card" ref="container" v-bind:style="{'max-width':width || '100%'}">
        <div class="card-body">

            <tabs ref="dashboard-tabs" cache-lifetime="0" :options="{useUrlFragment:true}" @changed="onTabChange">
              <tab name="Assmt's By Insurer" id="assessments-by-insurer" :class-name="'tab-content-table'">

                <h4 class="title-text chart-name">

                  <small v-show="chartShowBy === 'week'" >By Week </small>
                  <small v-show="chartShowBy === 'month'">By Month</small>

                  <b-dropdown right text="Options" variant="link" toggle-class="text-decoration-none" no-caret class="no-padding" dropright menu-class="z-index-high">
                    <template v-slot:button-content>
                      <i class="bx bx-chevron-down text-black"></i>
                    </template>
                    <b-dropdown-item @click="chartShowBy = 'week'; byInsurer = null; byRepairer = null;">
                      By Week
                    </b-dropdown-item>
                    <b-dropdown-item @click="chartShowBy = 'month'; byInsurer = null; byRepairer = null;">
                      By Month
                    </b-dropdown-item>
                  </b-dropdown>

                </h4>

                <div>
                    <strong>Insurer Name:</strong>
                    <template v-if="chartShowBy == 'week'">
                      {{ byInsurer || 'All' }}
                      <b-dropdown right text="Options" variant="link" toggle-class="text-decoration-none" no-caret class="no-padding" dropright menu-class="z-index-high">
                        <template v-slot:button-content>
                          <i class="bx bx-chevron-down text-black"></i>
                        </template>
                        <b-dropdown-item @click="byInsurer = null">
                          All
                        </b-dropdown-item>
                        <b-dropdown-item v-for="insurer of insurersList" v-bind:key="insurer" @click="byInsurer = insurer">
                          {{ insurer }}
                        </b-dropdown-item>
                      </b-dropdown>
                    </template>
                    <template v-if="chartShowBy == 'month'">
                      {{ byInsurer || 'All' }}
                      <b-dropdown right text="Options" variant="link" toggle-class="text-decoration-none" no-caret class="no-padding" dropright menu-class="z-index-high">
                        <template v-slot:button-content>
                          <i class="bx bx-chevron-down text-black"></i>
                        </template>
                        <b-dropdown-item @click="byInsurer = null">
                          All
                        </b-dropdown-item>
                        <b-dropdown-item v-for="insurer of insurersListMonth" v-bind:key="insurer" @click="byInsurer = insurer">
                          {{ insurer }}
                        </b-dropdown-item>
                      </b-dropdown>
                    </template>

                    <br>

                  <template v-if="chartShowBy == 'week'">
                    <template v-if="byInsurer == null">
                      <strong>Repairs Assessed:</strong> {{ weekSummary.repairsAssessed }} <br>
                      <strong>Assessed Repair Cost:</strong> {{ weekSummary.assessedRepairCost | formatMoney }} <br>
                      <strong>Adjusted Repair Cost:</strong> {{ weekSummary.adjustedRepairCost | formatMoney }} <br>
                      <strong>Total Saved Cost:</strong> {{ weekSummary.savedCost | formatMoney }}
                    </template>
                    <template v-else>
                      <strong>Repairs Assessed:</strong> {{ chartData.byInsurer[byInsurer].weekSummary.repairsAssessed }} <br>
                      <strong>Assessed Repair Cost:</strong> {{ chartData.byInsurer[byInsurer].weekSummary.assessedRepairCost | formatMoney }} <br>
                      <strong>Adjusted Repair Cost:</strong> {{ chartData.byInsurer[byInsurer].weekSummary.adjustedRepairCost | formatMoney }} <br>
                      <strong>Total Saved Cost:</strong> {{ chartData.byInsurer[byInsurer].weekSummary.savedCost | formatMoney }}
                    </template>
                  </template>
                  <template v-if="chartShowBy == 'month'">
                    <template v-if="byInsurer == null">
                      <strong>Repairs Assessed:</strong> {{ monthSummary.repairsAssessed }} <br>
                      <strong>Assessed Repair Cost:</strong> {{ monthSummary.assessedRepairCost | formatMoney }} <br>
                      <strong>Adjusted Repair Cost:</strong> {{ monthSummary.adjustedRepairCost | formatMoney }} <br>
                      <strong>Total Saved Cost:</strong> {{ monthSummary.savedCost | formatMoney }}
                    </template>
                    <template v-else>
                      <strong>Repairs Assessed:</strong> {{ chartData.byInsurerMonth[byInsurer].monthSummary.repairsAssessed }} <br>
                      <strong>Assessed Repair Cost:</strong> {{ chartData.byInsurerMonth[byInsurer].monthSummary.assessedRepairCost | formatMoney }} <br>
                      <strong>Adjusted Repair Cost:</strong> {{ chartData.byInsurerMonth[byInsurer].monthSummary.adjustedRepairCost | formatMoney }} <br>
                      <strong>Total Saved Cost:</strong> {{ chartData.byInsurerMonth[byInsurer].monthSummary.savedCost | formatMoney }}
                    </template>
                  </template>


                </div>

              </tab>
              <tab name="Assmt's By Repairer" id="assessments-by-repairer" :class-name="'tab-content-table'">


                <h4 class="title-text chart-name">

                  <small v-show="chartShowBy === 'week'" >By Week </small>
                  <small v-show="chartShowBy === 'month'">By Month</small>

                  <b-dropdown right text="Options" variant="link" toggle-class="text-decoration-none" no-caret class="no-padding" dropright menu-class="z-index-high">
                    <template v-slot:button-content>
                      <i class="bx bx-chevron-down text-black"></i>
                    </template>
                    <b-dropdown-item @click="chartShowBy = 'week'; byInsurer = null; byRepairer = null;">
                      By Week
                    </b-dropdown-item>
                    <b-dropdown-item @click="chartShowBy = 'month'; byInsurer = null; byRepairer = null;">
                      By Month
                    </b-dropdown-item>
                  </b-dropdown>

                </h4>

                <div>
                  <strong>Repairer Name:</strong>
                  <template v-if="chartShowBy == 'week'">
                    {{ byRepairer ? repairersList[byRepairer].name : 'All' }}
                    <b-dropdown right text="Options" variant="link" toggle-class="text-decoration-none" no-caret class="no-padding" dropright menu-class="z-index-high">
                      <template v-slot:button-content>
                        <i class="bx bx-chevron-down text-black"></i>
                      </template>
                      <b-dropdown-item @click="byRepairer = null">
                        All
                      </b-dropdown-item>
                      <b-dropdown-item v-for="repairer of repairersList" v-bind:key="repairer.id" @click="byRepairer = repairer.id">
                        {{ repairer.name }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>
                  <template v-if="chartShowBy == 'month'">
                    {{ byRepairer ? repairersListMonth[byRepairer].name : 'All' }}
                    <b-dropdown right text="Options" variant="link" toggle-class="text-decoration-none" no-caret class="no-padding" dropright menu-class="z-index-high">
                      <template v-slot:button-content>
                        <i class="bx bx-chevron-down text-black"></i>
                      </template>
                      <b-dropdown-item @click="byRepairer = null">
                        All
                      </b-dropdown-item>
                      <b-dropdown-item v-for="repairer of repairersListMonth" v-bind:key="repairer.id" @click="byRepairer = repairer.id">
                        {{ repairer.name }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>
                  <br>

                  <template v-if="chartShowBy == 'week'">
                    <template v-if="byRepairer == null">
                      <strong>Repairs Assessed:</strong> {{ weekSummary.repairsAssessed }} <br>
                      <strong>Assessed Repair Cost:</strong> {{ weekSummary.assessedRepairCost | formatMoney }} <br>
                      <strong>Adjusted Repair Cost:</strong> {{ weekSummary.adjustedRepairCost | formatMoney }} <br>
                      <strong>Total Saved Cost:</strong> {{ weekSummary.savedCost | formatMoney }}
                    </template>
                    <template v-else>
                      <strong>Repairs Assessed:</strong> {{ chartData.byRepairer[byRepairer].weekSummary.repairsAssessed }} <br>
                      <strong>Assessed Repair Cost:</strong> {{ chartData.byRepairer[byRepairer].weekSummary.assessedRepairCost | formatMoney }} <br>
                      <strong>Adjusted Repair Cost:</strong> {{ chartData.byRepairer[byRepairer].weekSummary.adjustedRepairCost | formatMoney }} <br>
                      <strong>Total Saved Cost:</strong> {{ chartData.byRepairer[byRepairer].weekSummary.savedCost | formatMoney }}
                    </template>
                  </template>
                  <template v-if="chartShowBy == 'month'">
                    <template v-if="byRepairer == null">
                      <strong>Repairs Assessed:</strong> {{ monthSummary.repairsAssessed }} <br>
                      <strong>Assessed Repair Cost:</strong> {{ monthSummary.assessedRepairCost | formatMoney }} <br>
                      <strong>Adjusted Repair Cost:</strong> {{ monthSummary.adjustedRepairCost | formatMoney }} <br>
                      <strong>Total Saved Cost:</strong> {{ monthSummary.savedCost | formatMoney }}
                    </template>
                    <template v-else>
                      <strong>Repairs Assessed:</strong> {{ chartData.byRepairerMonth[byRepairer].monthSummary.repairsAssessed }} <br>
                      <strong>Assessed Repair Cost:</strong> {{ chartData.byRepairerMonth[byRepairer].monthSummary.assessedRepairCost | formatMoney }} <br>
                      <strong>Adjusted Repair Cost:</strong> {{ chartData.byRepairerMonth[byRepairer].monthSummary.adjustedRepairCost | formatMoney }} <br>
                      <strong>Total Saved Cost:</strong> {{ chartData.byRepairerMonth[byRepairer].monthSummary.savedCost | formatMoney }}
                    </template>
                  </template>

                </div>

              </tab>
              <tab name="Assmt's W/Recoveries" id="assessments-with-recoveries" :class-name="'tab-content-table'">

                <h4 class="title-text chart-name">
                  <small><b>Period</b> — {{ this.period.from }} to {{ this.period.to }}</small>
                </h4>

                <div>
                  <strong>Legal Firm Name:</strong>
                  {{ byLegalFirm ? legalFirmsList[byLegalFirm].name : 'All' }}
                  <b-dropdown right text="Options" variant="link" toggle-class="text-decoration-none" no-caret class="no-padding" dropright menu-class="z-index-high">
                    <template v-slot:button-content>
                      <i class="bx bx-chevron-down text-black"></i>
                    </template>
                    <b-dropdown-item @click="byLegalFirm = null">
                      All
                    </b-dropdown-item>
                    <b-dropdown-item v-for="lf of legalFirmsList" v-bind:key="lf.id" @click="byLegalFirm = lf.id">
                      {{ lf.name }}
                    </b-dropdown-item>
                  </b-dropdown>
                  <br>

                  <template v-if="byLegalFirm == null">
                    <strong>Assmt's Submitted:</strong> {{ chartData.byLegalFirm.all.assessmentsSubmitted }} <br>
                    <strong>Value Invoiced:</strong> {{ chartData.byLegalFirm.all.valueInvoiced | formatMoney }} <br>
                    <strong>Assmt's In Recovery</strong> {{ chartData.byLegalFirm.all.assessmentsInRecovery  }} <br>
                    <strong>Average Nbr Days in Recovery</strong> {{ chartData.byLegalFirm.all.daysInRecovery }}
                  </template>
                  <template v-else-if="chartData.byLegalFirm[byLegalFirm]">
                    <strong>Assmt's Submitted:</strong> {{ chartData.byLegalFirm[byLegalFirm].all.assessmentsSubmitted }} <br>
                    <strong>Value Invoiced:</strong> {{ chartData.byLegalFirm[byLegalFirm].all.valueInvoiced | formatMoney }} <br>
                    <strong>Assmt's In Recovery</strong> {{ chartData.byLegalFirm[byLegalFirm].all.assessmentsInRecovery  }} <br>
                    <strong>Average Nbr Days in Recovery</strong> {{ chartData.byLegalFirm[byLegalFirm].all.daysInRecovery }}
                  </template>
                </div>

              </tab>
            </tabs>

          <div id="chart-area"></div>
        </div>
    </div>
</template>


<script>
    /* eslint-disable */

    import chart from "tui-chart";
    import "tui-chart/dist/tui-chart.css";
    import _ from 'lodash';
    // import moment from "moment";
    import accounting from "accounting";
    import Axios from "axios";
    import Vue from "vue";
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "WidgetAssessmentsTabs",
        props: {
        },
        components: {},
        mounted() {
            this.loadReports();
            // this is bad
            const menuBtn = document.querySelector(".nav-link.toggle-button");
            if (menuBtn) {
                menuBtn.addEventListener("click", () => {
                    this.resizeChart();
                });
            }

            window.addEventListener("orientationchange", () => {
                this.resizeChart();
            });
        },
      data() {
            return {
                period: { from:'', to:''},
                legalFirmsList: [],
                months: [],
                monthsData: [],
                days: {},
                currentTab: null,
                byRepairer: null,
                byLegalFirm: null,
                chartShowBy: 'week',
                repairersList: [],
                repairersListMonth: [],
                byInsurer: null,
                insurersList:[],
                insurersListMonth:[],
                weekSummary:{
                  repairsAssessed: 0,
                  assessedRepairCost: 0,
                  adjustedRepairCost: 0,
                  savedCost: 0,
                  days: [],
                },
                monthSummary:{
                  repairsAssessed: 0,
                  assessedRepairCost: 0,
                  adjustedRepairCost: 0,
                  savedCost: 0,
                  months: [],
                },
                width: 600,
                testData: {
                    credits: {},
                    categories: [],
                    series: [
                        {
                            name: "Orders",
                            data: []
                        }
                    ]
                },
                yAxisMax: 80000,
                ordersOrderedCount: 0,
                ordersOnBackOrderCount: 0,
                ordersOnRouteCount: 0,
                ordersDeliveredCount: 0,
                space: 16,
                ordersChart: null,
                showChartBy: {
                    type: 'week',
                    label: 'By Week'
                },
              chartData: {
                month: {
                  credits: {},
                  categories: [],
                  series: [
                    {
                      name: "Test name",
                      data: []
                    }
                  ],
                  maxY: 100,
                },
                week: {
                  credits: {},
                  categories: [],
                  series: [
                    {
                      name: "Orders",
                      data: [5000, 7000]
                    }
                  ],
                  maxY: 100,
                },
                monthsData: {},
                byLegalFirm: {
                  all: {}
                },
                byInsurer: {
                  all: {}
                },
                byRepairer: {
                  all: {}
                },
                byInsurerMonth: {
                  all: {}
                },
                byRepairerMonth: {
                  all: {}
                },
                allMonth: {
                  credits: {},
                  categories: [],
                  series: [
                    {
                      name: "Orders",
                      data: [5000, 7000]
                    }
                  ],
                  maxY: 100,
                },
              },
              chartData2: {
                month: {
                  credits: {},
                  categories: [
                      'One',
                      'Two',
                      'Therr'
                  ],
                  series: [
                    {
                      name: "Test name",
                      data: [20000,40000,30000]
                    }
                  ],
                  maxY: 100,
                },
                week: {
                  credits: {},
                  categories: [],
                  series: [
                    {
                      name: "Orders",
                      data: [5000, 7000]
                    }
                  ],
                  maxY: 100,
                }
              }
            };
        },
        methods: {
          ...mapActions({
            actionLoadReport: 'dashboard/loadReports',
          }),
          isNeedRefresh(data) {
            if (_.isEmpty(data)) {
              return false;
            }

            if (this.monthSummary.repairsAssessed !== data.allMonth.monthSummary.repairsAssessed) {
              return true;
            } else if (this.monthSummary.assessedRepairCost !== data.allMonth.monthSummary.assessedRepairCost) {
              return true;
            } else if (this.monthSummary.adjustedRepairCost !== data.allMonth.monthSummary.adjustedRepairCost) {
              return true;
            } else if (this.monthSummary.savedCost !== data.allMonth.monthSummary.savedCost) {
              return true;
            }

            if (this.chartData.byLegalFirm.all.assessmentsSubmitted !== data.byLegalFirm.all.assessmentsSubmitted) {
              return true;
            } else if (this.chartData.byLegalFirm.all.valueInvoiced !== data.byLegalFirm.all.valueInvoiced) {
              return true;
            } else if (this.chartData.byLegalFirm.all.assessmentsInRecovery !== data.byLegalFirm.all.assessmentsInRecovery) {
              return true;
            } else if (this.chartData.byLegalFirm.all.daysInRecovery !== data.byLegalFirm.all.daysInRecovery) {
              return true;
            }

            return false;
          },
          loadReports() {
            this.loadChartData();
            this.actionLoadReport()
              .then(() => {
                if (this.isNeedRefresh(this.getterReports)) {
                  this.loadChartData();
                }
              });
          },
          onTabChange(e){
            this.currentTab = e.tab.id;
            this.byInsurer = null;
            this.byRepairer = null;
            this.byLegalFirm = null;
            this.createChart();
          },
          loadChartData(){
              if (_.isEmpty(this.getterReports)) {
                return;
              }
              this.chartData.week = this.getterReports.all;
              this.chartData.allMonth = this.getterReports.allMonth;
              this.weekSummary = this.getterReports.all.weekSummary;
              this.monthSummary = this.getterReports.allMonth.monthSummary;
              this.chartData.byInsurer = this.getterReports.byInsurer;
              this.chartData.byRepairer = this.getterReports.byRepairer;
              this.chartData.byInsurerMonth = this.getterReports.byInsurerMonth;
              this.chartData.byRepairerMonth = this.getterReports.byRepairerMonth;
              this.insurersList = this.getterReports.insurersList;
              this.insurersListMonth = this.getterReports.insurersListMonth;
              this.repairersList = this.getterReports.repairersList;
              this.repairersListMonth = this.getterReports.repairersListMonth;
              this.legalFirmsList = this.getterReports.legalFirms;
              this.months = this.getterReports.months;
              this.chartData.monthsData = this.getterReports.monthsData;
              this.chartData.byLegalFirm = this.getterReports.byLegalFirm;
              this.days = this.getterReports.days;
              this.period = this.getterReports.period;
              this.initChart();
          },
          initChart(){
            this.setChartDataByKey(this.months, 'month');
            this.createChart();
            this.showByMonth();
          },
            openRouteWithHash(hash) {
                this.$router.push({name: 'SuppliersPartsOrders', hash: '#' + hash});
            },
            setShowChartBy(type) {
                let result = {
                    type: 'month',
                    label: 'by Month'
                };
                if (type === 'week' && !_.isEmpty(this.chartData.week.categories)) {
                    result = {
                        type: 'week',
                        label: 'By Week'
                    };
                }
                this.createChart(result.type);
                this.showChartBy = result;
                this.resizeChart();
            },
            setChartDataByKey(chartData = {}, chartKey = '') {
                let categories = [],
                    data = [];
                _.forEach(chartData, (value, key) => {
                    categories.push(key);
                    data.push(value);
                });
                //this.chartData[chartKey]. = chartData[chartKey].credits;
                this.chartData[chartKey].categories = categories;
                this.chartData[chartKey].series[0].data = data;
            },
            resizeChart() {
                if (!this.$refs.container) return;
                setTimeout(() => {
                    this.ordersChart.resize({
                        width: this.$refs.container.clientWidth - this.space,
                        height: this.height
                    });
                }, 100);
            },
            createChart(type = 'month') {
                const container = document.getElementById("chart-area");

              let data = this.chartData.week.data;
              if(this.chartShowBy == 'month'){
                data = this.chartData.allMonth.data;
              }
              if(this.currentTab == "assessments-by-insurer"){
                if(this.byInsurer){
                  if(this.chartShowBy == 'week') {
                    data = this.chartData.byInsurer[this.byInsurer].data;
                  }
                  if(this.chartShowBy == 'month'){
                    data = this.chartData.byInsurerMonth[this.byInsurer].data;
                  }
                }
              } else if(this.currentTab == "assessments-by-repairer"){
                if(this.byRepairer){
                  if(this.chartShowBy == 'week') {
                    data = this.chartData.byRepairer[this.byRepairer].data;
                  }
                  if(this.chartShowBy == 'month'){
                    data = this.chartData.byRepairerMonth[this.byRepairer].data;
                  }
                }
              } else if(this.currentTab == "assessments-with-recoveries"){
                if(this.byLegalFirm) {
                  data = this.chartData.monthsData[this.byLegalFirm];
                } else {
                  data = this.chartData.monthsData.all;
                }
              }
              //let data = (type === 'month') ? this.months : {};

                // let months = _.map(this.months, (item, key) => {
                //    return {
                //        date: key,
                //        value: item
                //    };
                // });

                const theme = {
                    chart: {
                        fontFamily: "Nunito Sans",
                        fontSize: "16px"
                    },
                    series: {
                        colors: ["#FFCFB1","#B0AAF1","#B0FFB1","#aaeef0"],
                        width: "50px"
                    },
                    xAxis: {
                        tickColor: "rgba(27,30,56,0.1)",
                        label: {
                            fontSize: 14,
                            fontFamily: "Nunito Sans",
                            fontWeight: "bold",
                            color: "#1C1F39"
                        }
                    },
                    yAxis: {
                        tickColor: "rgba(27,30,56,0.1)",
                        label: {
                            fontSize: 14,
                            fontFamily: "Nunito Sans",
                            fontWeight: "bold",
                            color: "#1C1F39"
                        }
                    },
                    plot: {
                        lineColor: "rgba(27,30,56,0.1)",
                        background: "#EFEFEF"
                    }
                };

             // console.log('chartData', data);
              //
              // this.ordersChart = chart.columnChart(container, data, options);
              // return;
              const options = {
                chartExportMenu: {
                  visible: false
                },
                chart: {
                  // title: 'Average Temperature',
                  width: parseInt(this.$refs.container.clientWidth) - parseInt(this.space),
                  height: parseInt(this.height) || 304,
                  format: "1,000"
                },
                xAxis: {
                  title: "&nbsp;",
                  pointOnColumn: true
                },
                yAxis: {
                  title: "",
                  prefix: "$",
                  // min: 0,
                  // max: 80000,
                },
                tooltip: {
                  offsetX: 1,
                  offsetY: -15,
                  template: (category, item) => {
                    if(this.currentTab == 'assessments-with-recoveries'){



                      let body = this.byLegalFirm ?`<div  class="tt-body">
                            <ul>
                                <li>Legal Firm <span class="tt-value">${this.legalFirmsList[this.byLegalFirm].name}</span></li>
                                <li>Assessments Submitted <span class="tt-value">${this.chartData.byLegalFirm[this.byLegalFirm][category].assessmentsSubmitted}</span></li>
                                <li>Assessments Settled <span class="tt-value">${this.chartData.byLegalFirm[this.byLegalFirm][category].assessmentsSettled}</span></li>
                                <li>Total Invoiced <span class="tt-value">${Vue.filter('formatMoney')(this.chartData.byLegalFirm[this.byLegalFirm][category].valueInvoiced)}</span></li>
                            </ul>
                            </div>` : '';
                      return (
                          '<div class="tooltip-template">' +
                          // head +
                          body +
                          '<div class="point"></div></div>'
                      );
                    }

                    let days = {
                      Monday: 0,
                      Tuesday: 1,
                      Wednesday: 2,
                      Thursday: 3,
                      Friday: 4,
                      Saturday: 5,
                      Sunday: 6,
                    }

                    let day = days[item.category];

                    let month = this.months.indexOf(item.category);

                    let head =  '<div class="tt-title">' + this.days[item.category] + "</div>";

                    if(this.chartShowBy == 'month'){
                      head =  '<div class="tt-title">' + this.months[month] + "</div>";
                    }

                    let body = '';

                    if(
                        (this.currentTab == 'assessments-by-insurer' && !this.byInsurer)
                        || (this.currentTab == 'assessments-by-repairer' && !this.byRepairer)
                    ){

                      if(this.chartShowBy == 'week'){
                        body = `<div  class="tt-body">
                            <ul>
                            <li>Assessments Proc <span class="tt-value">${this.chartData.week.weekSummary.daysDetails[day].repairsAssessed}</span></li>
                            <li>Assessed Repair Cost <span class="tt-value">${Vue.filter('formatMoney')(this.chartData.week.weekSummary.daysDetails[day].assessedRepairCost)}</span></li>
                            <li>Adjusted Repair Cost <span class="tt-value">${Vue.filter('formatMoney')(this.chartData.week.weekSummary.daysDetails[day].adjustedRepairCost)}</span></li>
                            <li>Total Saved <span class="tt-value">${Vue.filter('formatMoney')(this.chartData.week.weekSummary.daysDetails[day].savedCost)}</span></li>
                            </ul>
                            </div>`;
                      }

                      if(this.chartShowBy == 'month'){
                        body = `<div  class="tt-body">
                            <ul>
                            <li>Assessments Proc <span class="tt-value">${this.chartData.allMonth.monthSummary.monthsDetails[month].repairsAssessed}</span></li>
                            <li>Assessed Repair Cost <span class="tt-value">${Vue.filter('formatMoney')(this.chartData.allMonth.monthSummary.monthsDetails[month].assessedRepairCost)}</span></li>
                            <li>Adjusted Repair Cost <span class="tt-value">${Vue.filter('formatMoney')(this.chartData.allMonth.monthSummary.monthsDetails[month].adjustedRepairCost)}</span></li>
                            <li>Total Saved <span class="tt-value">${Vue.filter('formatMoney')(this.chartData.allMonth.monthSummary.monthsDetails[month].savedCost)}</span></li>
                            </ul>
                            </div>`;
                      }

                    } else if(this.currentTab == 'assessments-by-insurer' && this.byInsurer) {

                      if(this.chartShowBy == 'week'){
                        body = `<div  class="tt-body">
                            <ul>
                            <li>Assessments Proc <span class="tt-value">${this.chartData.byInsurer[this.byInsurer].weekSummary.daysDetails[day].repairsAssessed}</span></li>
                            <li>Assessed Repair Cost <span class="tt-value">${Vue.filter('formatMoney')(this.chartData.byInsurer[this.byInsurer].weekSummary.daysDetails[day].assessedRepairCost)}</span></li>
                            <li>Adjusted Repair Cost <span class="tt-value">${Vue.filter('formatMoney')(this.chartData.byInsurer[this.byInsurer].weekSummary.daysDetails[day].adjustedRepairCost)}</span></li>
                            <li>Total Saved <span class="tt-value">${Vue.filter('formatMoney')(this.chartData.byInsurer[this.byInsurer].weekSummary.daysDetails[day].savedCost)}</span></li>
                            </ul>
                            </div>`;
                      }

                      if(this.chartShowBy == 'month'){
                        body = `<div  class="tt-body">
                            <ul>
                            <li>Assessments Proc <span class="tt-value">${this.chartData.byInsurerMonth[this.byInsurer].monthSummary.monthsDetails[month].repairsAssessed}</span></li>
                            <li>Assessed Repair Cost <span class="tt-value">${Vue.filter('formatMoney')(this.chartData.byInsurerMonth[this.byInsurer].monthSummary.monthsDetails[month].assessedRepairCost)}</span></li>
                            <li>Adjusted Repair Cost <span class="tt-value">${Vue.filter('formatMoney')(this.chartData.byInsurerMonth[this.byInsurer].monthSummary.monthsDetails[month].adjustedRepairCost)}</span></li>
                            <li>Total Saved <span class="tt-value">${Vue.filter('formatMoney')(this.chartData.byInsurerMonth[this.byInsurer].monthSummary.monthsDetails[month].savedCost)}</span></li>
                            </ul>
                            </div>`;
                      }

                    } else if (this.currentTab == 'assessments-by-repairer' && this.byRepairer) {

                      if(this.chartShowBy == 'week'){
                        body = `<div  class="tt-body">
                            <ul>
                            <li>Assessments Proc <span class="tt-value">${this.chartData.byRepairer[this.byRepairer].weekSummary.daysDetails[day].repairsAssessed}</span></li>
                            <li>Assessed Repair Cost <span class="tt-value">${Vue.filter('formatMoney')(this.chartData.byRepairer[this.byRepairer].weekSummary.daysDetails[day].assessedRepairCost)}</span></li>
                            <li>Adjusted Repair Cost <span class="tt-value">${Vue.filter('formatMoney')(this.chartData.byRepairer[this.byRepairer].weekSummary.daysDetails[day].adjustedRepairCost)}</span></li>
                            <li>Total Saved <span class="tt-value">${Vue.filter('formatMoney')(this.chartData.byRepairer[this.byRepairer].weekSummary.daysDetails[day].savedCost)}</span></li>
                            </ul>
                            </div>`;
                      }

                      if(this.chartShowBy == 'month'){
                        body = `<div  class="tt-body">
                            <ul>
                            <li>Assessments Proc <span class="tt-value">${this.chartData.byRepairerMonth[this.byRepairer].monthSummary.monthsDetails[month].repairsAssessed}</span></li>
                            <li>Assessed Repair Cost <span class="tt-value">${Vue.filter('formatMoney')(this.chartData.byRepairerMonth[this.byRepairer].monthSummary.monthsDetails[month].assessedRepairCost)}</span></li>
                            <li>Adjusted Repair Cost <span class="tt-value">${Vue.filter('formatMoney')(this.chartData.byRepairerMonth[this.byRepairer].monthSummary.monthsDetails[month].adjustedRepairCost)}</span></li>
                            <li>Total Saved <span class="tt-value">${Vue.filter('formatMoney')(this.chartData.byRepairerMonth[this.byRepairer].monthSummary.monthsDetails[month].savedCost)}</span></li>
                            </ul>
                            </div>`;
                      }

                    }

                    return (
                        '<div class="tooltip-template">' +
                        head +
                        body +
                        '<div class="point"></div></div>'
                    );
                  }
                },
                legend: {
                  visible: false
                },
                series: {
                  barWidth: 30
                }
              };

              chart.registerTheme("myTheme", theme);
              options.theme = "myTheme";

              container.innerHTML = '';

              if(this.currentTab == "assessments-with-recoveries"){
                options.series.stack = { type: 'normal' };
              }

              this.ordersChart = chart.columnChart(container, data, options);

              window.addEventListener("resize", () => {
                  this.resizeChart();
              });
            },
            showByMonth() {
                this.setShowChartBy('month');
            },
            showByWeek() {
                this.setShowChartBy('week');
            },
        },
        computed: {
          ...mapGetters({
            getterReports: 'dashboard/getReports',
          }),
          computedChartData(){
            let categories = [];
            let data = [];
            for(let d in this.months){
              let m = this.months[d];
              categories.push(m.month_label);
              data.push(m.settled)
            }

            return {
              credits: {},
              categories,
              series: [
                {
                  name: "Test name",
                  data
                }
              ],
              maxY: 100,
            };
          }
        },
        watch: {
          months(){
            setTimeout(this.initChart, 200)
          },
          byInsurer(){
            this.createChart();
          },
          byRepairer(){
            this.createChart();
          },
          byLegalFirm(){
            this.createChart();
          },
          chartShowBy(){
            this.createChart();
          },
        },
    };
</script>

<style scoped>
    @import url(../../../../styles/dashboard/style.css);

    .chart-name {
        margin-top: 20px;
        font-size: 18px;
        font-weight: bold;
        line-height: 24px;
        display: flex;
        align-items: center;
    }

    .chart-name i {
        font-size: 32px;
        cursor: pointer;
    }

    .chart-name small {
        line-height: 22px;
        font-size: 16px;
        font-weight: 600;
        margin-left: 5px;
    }

    .list-values .clickable:hover {
        color: #000000;
    }

    .card-item .body-item {

    }
</style>

<style>

.z-index-high {
  z-index: 9999;
}
.no-padding .dropdown-toggle {
  padding: 0 !important;
}
</style>

