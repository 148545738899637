<script>
    /* eslint-disable */
    import NumberFormatter from "./utility/number-formatter";

    export default {
        name: 'block-rental-vehicle',
        props: {
            details: {
              type: Object,
              default: function () {
                return {
                  rentalAllocationRequired: null,
                  rentalAgreementNumber:'',
                  hirerName: '',
                  hirerFrom: {},
                  rentalCompany: '',
                  hireStartDate: '',
                  hireEndDate: '',
                  countDaysOnHire: 0,
                  dailyCostOfHire: 0,
                  excessReduction: 0,
                  adminFee: 0,
                  recoveryFee: 0,
                  deliveryFee: 0,
                  pickUpFee: 0,
                  subtotal: 0,
                  totalDue: 0,
                  gst: 0,
                  operationalCost: 0,
                };
              }
            },
            vehicle: {
                type: Object,
                default: function () {
                    return {
                        rego: '',
                        make: '',
                        model: '',
                        conditionAtHire: '',
                        conditionOnReturn: '',
                        conditionReport: '',
                    };
                }
            },
            allocation: {
              type: Object,
              default: function () {
                return {
                  creditCardVerification: null,
                  hirerSentNotice: '',
                  dateHirerNoticeSent: '',
                  rentalRef: '',
                  customerDeliveryLocation: '',
                  deliveryDriver: '',
                  deliverySentNotice: '',
                  dateDeliveryNoticeSent: '',
                  pickUpLocation: '',
                  startHireOdometer: '',
                  dropOfLocation: '',
                  endHireOdometer: '',
                  licenseVerification: 'Not verified',
                };
              }
            },
            isAllDisable: {
                type: Boolean,
                default: function () {
                    return false;
                }
            }

        },
        data: function () {
            return {};
        },
        methods: {},

        computed: {},
        watch: {},
        components: {
          NumberFormatter,
        }

    };
</script>
<template>
    <div class="card">
        <div class="card-header bg-navyblue">
            <strong>Vehicle</strong>
        </div>
        <div class="card-block bg-white">

            <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Rego</label>
                <div class="col-sm-8 col-8">
                    <input type="text" v-model="vehicle.rego" class="form-control" :disabled="isAllDisable">
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Make</label>
                <div class="col-sm-8 col-8" ref="multi1">
                    <input type="text" v-model="vehicle.make" class="form-control" :disabled="isAllDisable">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Model</label>
                <div class="col-sm-8 col-8">
                    <input type="text" v-model="vehicle.model" class="form-control" :disabled="isAllDisable">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Condition at hire</label>
                <div class="col-sm-8 col-8">
                    <input type="text" v-model="vehicle.conditionAtHire" class="form-control" :disabled="isAllDisable">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Condition on return</label>
                <div class="col-sm-8 col-8">
                    <input type="text" v-model="vehicle.conditionOnReturn" class="form-control" :disabled="isAllDisable">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Condition Report</label>
                <div class="col-sm-8 col-8">
                    <textarea type="text" v-model="vehicle.conditionReport" class="form-control" row="10" :disabled="isAllDisable" style="height: 175px;"></textarea>
                </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-4 col-form-label">Start Hire Odometer</label>
              <div class="col-sm-8 col-8">
                <number-formatter v-model="allocation.startHireOdometer" class="form-control numeric" format="0" :isDisabled="isAllDisable"></number-formatter>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-4 col-form-label">End Hire Odometer</label>
              <div class="col-sm-8 col-8">
                <number-formatter v-model="allocation.endHireOdometer" class="form-control numeric" format="0" :isDisabled="isAllDisable"></number-formatter>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-4 col-form-label">Operational Cost Per/Day</label>
              <div class="col-sm-8 col-8">
                <number-formatter v-model="details.operationalCost" class="form-control numeric" format="$0,0.00" :isDisabled="isAllDisable"></number-formatter>
              </div>
            </div>
        </div>
    </div>

</template>

<style scoped>
    .V3 .new-card .card {
        margin-bottom: 0px;
    }

    .cards-edit-page .card-block {
        padding-right: 0;
    }

    .V3 body.V3 .multiselect__select {
        height: 35px;
        right: 0px;
        top: 0px;
    }

    .V3 .card-new-body .card-block {
        padding: 0px;
    }

    .V3 .col-form-label {
        padding: 8px 0;
    }
    @media (max-width: 575.98px) {
        .V3 .col-block .col-form-label{
            padding: 8px 15px;
        }
    }
</style>
