<template>
    <div>
        <div class="row">
            <div class="col-md-4 pr-0">
                <block-insured-insured :owners="owners" :insured="insured.insured" v-on="$listeners"></block-insured-insured>
            </div>
            <div class="col-md-4 pr-0">
                <block-insured-vehicle :vehicle="insured.vehicle"></block-insured-vehicle>
            </div>
            <div class="col-md-4">
                <insured-rental-context-tab
                        @change-active-rental-tab="changeActiveRentalTab"
                        v-on="$listeners"
                        :additionals="insured.insured.additionals"
                        :additionals-to-delete="insured.insured.additionalsToDelete"
                        :reasons="insured.insured.reasons"
                        :rentalTotal="insured.rentalTotal"
                        :rental="insured.rental"
                        :rentals="rentals"
                        :claim="claim"
                        :referrers="referrers"
                        :referrer="insured.referrer"
                        :on-referrer-changed="onReferrerChanged"
                        :assessment="assessment"
                        :settled="insured.insured.settled">
                </insured-rental-context-tab>
                <progress-bar
                        v-if="isShowProgressBar"
                        :current-step="currentStatus * 1"
                        :steps="statusesList"
                        :showing-count="3"
                        title="Progress"
                        :link="{
                          text: 'Full Activity',
                          href: '#',
                          isGoToActivityRef: true,
                        }"
                        v-on="$listeners"
                ></progress-bar>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 pr-0">
                <block-claims-insurer :insurer="insured.insurer"></block-claims-insurer>
            </div>
            <div class="col-md-4">
                <block-claims-repairer-new @on-repairer-select="onRepairerSelect"
                                           :inner-repairer="insured.innerRepairer || undefined"
                                           :repairers="repairers"
                                           :estimators="estimators"
                                           :estimator="insured.estimator"
                ></block-claims-repairer-new>
            </div>
            <div class="col-md-4">
                <div class="card">
                    <div class="card-header bg-navyblue">
                        <strong>Repairers Quote</strong>
                    </div>
                    <div class="card-block bg-white">
                        <div class="form-group row">
                            <label for="owner" class="col-sm-4 col-4 col-form-label px-0">Quote Nbr</label>
                            <div class="col-sm-8 col-8">
                                <input v-if="(assessment && assessment.type==='insured')" type="text" class="form-control" v-model="assessment.quoteNumber" readonly>
                                <input v-else type="text" class="form-control" readonly>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="owner" class="col-sm-4 col-4 col-form-label px-0">Quote Total</label>
                            <div class="col-sm-8 col-8">
                                <number-formatter v-if="insured && insured.insured && insured.insured.settled && insured.insured.settled.assessmentSubtotal !== null"
                                                  v-model="insured.insured.settled.assessmentSubtotal" class="form-control numeric"
                                                  format="$0,0.00" readonly></number-formatter>
                                <number-formatter v-else value="0" class="form-control numeric" format="$0,0.00" readonly></number-formatter>
                            </div>
                        </div>
                    </div>
                </div>

                <block-assessment-information
                        :assessment="assessment"
                        :isShow="(assessment && assessment.type==='insured')"
                        :with-recoveries-on="claim.withRecoveriesOn"
                        :claim="claim"
                        :settled="insured.insured.settled"
                        v-on="$listeners"
                ></block-assessment-information>

                <block-rental-information
                        :rentals="rentals"
                        :rental-total="insured.rentalTotal"
                        :vehicle-owner="insured.insured.name"
                        :settled="insured.insured.settled"
                ></block-rental-information>

                <block-personal-injury-information :settled="insured.insured.settled"></block-personal-injury-information>

            </div>
        </div>
        <div class="row">
            <div class="col-md-8">
                <block-accident-details :details="insured.accident.details">

                </block-accident-details>
            </div>
            <div class="col-md-4">
                <block-other-party-statement @saveSignature="saveSignature" :statement="insured.statement"/>

                <block-accident-towing :towing="insured.accident.towing">
                </block-accident-towing>

            </div>
        </div>
    </div>
</template>
<script>
    /* eslint-disable */
    import BlockOtherPartyStatement from "@/components/blocks/claim/statement";
    import BlockInsuredVehicle from "../../../components/block-insured-vehicle";
    import BlockInsuredInsured from "../../../components/block-insured-insured";
    import BlockClaimsInsurer from "../../../components/block-claims-insurer";
    import BlockClaimsRepairer from "../../../components/block-claims-repairer";
    import BlockClaimsRepairerNew from "../../../components/block-claims-repairer-new";
    import BlockProgress from "../../../components/block-progress";
    import ProgressBar from "@/components/ProgressBar";
    import BlockAccidentDetails from "../../../components/block-accident-details";
    import BlockAccidentTowing from "../../../components/block-accident-towing";
    import BlockAssessmentInformation from "../../../components/block-assessment-information";
    import InsuredRentalContextTab from "./insured-tab/insured-rental-context-tab";
    import NumberFormatter from "@/components/utility/number-formatter";
    import BlockRentalInformation from "@/components/block-rental-information";
    import BlockPersonalInjuryInformation from "@/components/block-personal-injury-information";
    import Axios from "axios";

    export default {
        name: 'claim-view-insured-tab',
        props: {
            insured: {
                type: Object,
                default() {
                    return {
                        insured: {
                            additionals: [],
                            additionalsToDelete: [],
                            address: {},
                            reasons: {},
                            settled: {
                                assessmentSubtotal: null,
                            },
                        },
                        insurer: {},
                        repairer: {},
                        vehicle: {},
                        referrer: {},
                        rental: {},
                        estimator: {},
                        accident: {
                            details: {},
                            towing: {},
                        },
                        innerRepairer: {},
                        statement:{
                            confirmation: null,
                            signature: null,
                        },
                    };
                },
            },
            'claim': {
                type: Object,
                default() {
                    return {
                        id: 0,
                        number: '',
                        totalAmountSettled: 0,
                        manualQuoteTotal: null,
                        manualAssessedTotal: null,
                        manualInvoiceValue: null,
                    };
                }
            },
            'assessment': Object | null,
            'statusesList': Array,
            'currentStatus': String | null,
            'owners': Array | null,
            'rentals': Array | null,
            'referrers': Array | null,
            'onReferrerChanged': Function,
            'repairers': Array | null,
            'estimators': Array | null,
        },
        data: function () {
            return {
                activeRentalTab: '',
            };
        },
        computed: {
            isShowProgressBar() {
                if (this.activeRentalTab == '#reasons-for-rental') {
                    return false;
                }
                return true;
            }
        },
        components: {
            InsuredRentalContextTab,
            BlockAssessmentInformation,
            BlockProgress,
            BlockInsuredVehicle,
            BlockInsuredInsured,
            BlockClaimsInsurer,
            BlockClaimsRepairer,
            BlockClaimsRepairerNew,
            ProgressBar,
            BlockAccidentDetails,
            BlockAccidentTowing,
            NumberFormatter,
            BlockRentalInformation,
            BlockPersonalInjuryInformation,
            BlockOtherPartyStatement,
        },
        methods: {
            saveSignature(signature) {
                this.$emit('saveSignature', {objectId: 0, signature: signature});
            },
            changeActiveRentalTab(tabHash) {
                this.activeRentalTab = tabHash;
            },
            onRepairerSelect(repairer) {
                Axios({
                    method: 'post',
                    responseType: 'json',
                    url: `/ir/claim/${this.claim.id}`,
                    data: {
                        insured: {
                            repairer: {
                                innerRepairerId: repairer.id
                            }
                        }
                    },
                })
                    .then(response => {
                        if (response && response.status === 200 && response.data && response.data._status) {
                            this.$emit('on-insured-repairer-updated');
                        }
                    })
                    .catch(error => console.log(error));
            },
        }
    };
</script>
