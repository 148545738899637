<script>
    /* eslint-disable */

    export default {
        name: 'block-rental-requirement',
        props: {
            rental: {
                type: Object,
                default: function () {
                    return {
                        requirersRental: null,
                        asap: null,
                        agrmntNbr: '',
                        company: '',
                    };
                }
            },
            rentals: {
                type: Array,
                default: function () {
                    return [];
                }
            },
            hideHeader: {
                type: Boolean,
                default: function () {
                    return false;
                }
            }
        },
        data: function () {
            return {};
        },
        methods: {},

        computed: {
            computedRentalCompany() {
                let find = _.find(this.rentals, function (r) {
                    return (r.details && r.details.rentalAllocationRequired && r.details.hirerFrom && r.details.hirerFrom.id == 0);
                });
                if (find) {
                    return find.details.rentalCompany;
                }
                return '';
            },
            computedRentalAgreementNumber() {
                let find = _.find(this.rentals, function (r) {
                    return (r.details && r.details.rentalAllocationRequired && r.details.hirerFrom && r.details.hirerFrom.id == 0);
                });
                if (find) {
                    return find.details.rentalAgreementNumber;
                }
                return '';
            },
        },
        watch: {},
        components: {}

    };
</script>
<template>
    <div class="card">
        <div v-if="!hideHeader" class="card-header bg-navyblue">
            <strong>Rental Requirement</strong>
        </div>
        <div class="card-block bg-white">
            <div class="form-group row">
                <label class="col-sm-5 col-5 col-form-label">Requires Rental</label>
                <div class="col-sm-7 col-7">
                    <label class="form-check-inline radio">
                        <input v-model="rental.requirersRental" class="form-check-input" type="radio" value="true" @click="(!!rental.requirersRental)?rental.requirersRental=null:null"
                               @change="rental.requirersRental=true">
                        <span class="icon"><i class='bx bx-check'></i></span>
                        <span class="text">Yes</span>

                    </label>
                    <label class="form-check-inline radio">
                        <input v-model="rental.requirersRental" class="form-check-input" type="radio" value="false" @click="(!rental.requirersRental)?rental.requirersRental=null:null"
                               @change="rental.requirersRental=false">
                        <span class="icon"><i class='bx bx-check'></i></span>
                        <span class="text">No</span>
                    </label>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-5 col-5 col-form-label">As soon as possible</label>
                <div class="col-sm-7 col-7">
                    <label class="form-check-inline radio">
                        <input v-model="rental.asap" class="form-check-input" type="radio" value="true" @click="(!!rental.asap)?rental.asap=null:null" @change="rental.asap=true">
                        <span class="icon"><i class='bx bx-check'></i></span>
                        <span class="text">Yes</span>

                    </label>
                    <label class="form-check-inline radio">
                        <input v-model="rental.asap" class="form-check-input" type="radio" value="false" @click="(!rental.asap)?rental.asap=null:null" @change="rental.asap=false">
                        <span class="icon"><i class='bx bx-check'></i></span>
                        <span class="text">No</span>
                    </label>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-5 col-5 col-form-label">Rental Agrmnt Nbr</label>
                <div class="col-sm-7 col-7">
                    <input type="text" v-model="computedRentalAgreementNumber" class="form-control" readonly>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-5 col-5 col-form-label">Rental Company</label>
                <div class="col-sm-7 col-7">
                    <input type="text" v-model="computedRentalCompany" class="form-control" readonly>
                </div>
            </div>

        </div>
    </div>

</template>

<style scoped>
    .V3 .new-card .card {
        margin-bottom: 0px;
    }

    .cards-edit-page .card-block {
        padding-right: 0;
    }

    .V3 body.V3 .multiselect__select {
        height: 35px;
        right: 0px;
        top: 0px;
    }

    .V3 .card-new-body .card-block {
        padding: 0px;
    }

    .V3 .col-form-label {
        padding: 8px 0;
    }
    @media (max-width: 575.98px) {
        .V3 .col-block .col-form-label{
            padding: 8px 15px;
        }
    }
</style>
