<template>

  <div>

    <table class="table">
      <thead>
      <tr>
        <th>Date</th>
        <th style="width: 80px">V/Class</th>
        <th>Details</th>
        <th style="width: 60px">Amount</th>
        <th style="width: 30px"></th>
      </tr>
      </thead>
      <tbody>

      <tr v-for="(toll, tn) in tolls" :key="tn">
        <td class="pl-0 pr-0">
          <b-form-datepicker
              v-model="toll.date"
              locale="en-GB"
              :date-format-options="{  day: '2-digit', month: '2-digit', year: 'numeric'}"
          ></b-form-datepicker>
        </td>
        <td class="pl-0 pr-0">
          <input class="form-control" type="text" v-model="toll.vClass">
        </td>
        <td class="pl-0 pr-0">
          <input class="form-control" type="text" v-model="toll.details">
        </td>
        <td class="pl-0 pr-0">
          <number-formatter v-model="toll.amount" class="form-control numeric" format="$0,0.00"></number-formatter>
        </td>
        <td class="pl-0 pr-0 text-center">
          <a @click.prevent="deleteToll(toll)" class="bx bx-trash text-danger"></a>
        </td>
      </tr>

      </tbody>
    </table>

    <button class="btn btn-link theme-color font-weight-bold" @click="addNewToll">
      Add <span class="fa fa-plus"></span>
    </button>

  </div>

</template>

<script>
import NumberFormatter from "./utility/number-formatter";

export default {
  name: "block-rental-tolls",
  components:{
    NumberFormatter,
  },
  props: {
    tolls: {
      type: [Array, null],
      default(){
        return [
          {
            id: 19990,
            date: '21-10-2021 00:00:00',
            vClass: 'Car',
            details: 'Some details',
            amount: '7.50'
          },
          {
            id: 19992,
            date: '07-10-2021',
            vClass: 'Car',
            details: 'Another toll',
            amount: '11'
          },
        ];
      }
    },
  },
  data(){
    return {
      newTolls: [],
    };
  },
  methods: {
    addNewToll(){
      this.$emit('add-new-toll');
    },
    deleteToll(toll){
      this.$bvModal.msgBoxConfirm('Please confirm that you want to delete this', {
        title: 'Are you sure?',
        okVariant: 'danger',
        okTitle: 'Yes',
        cancelTitle: 'No',
        footerClass: 'p-2',
        centered: true
      })
          .then(value => {
            if (!value) return;
            this.$emit('delete-toll', toll);
            let i = this.tolls.indexOf(toll);
            if(i >= 0){
              this.tolls.splice(i, 1)
            }
          })
          .catch(err => {
            console.error(err);
          });
    },
  },
}
</script>

<style scoped>

</style>
