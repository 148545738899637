<template>
    <div class="sub-tabs-component other-party-rental-context-tab">
        <tabs ref="claim-view-insured-rental-context-tab" @changed="changedTab" :options="{ defaultTabHash:'claim-type' }">
            <!--    eslint-disable-->
            <tab name="Claim Type" id="claim-type">
                <block-claim-type
                        :rentalTotal="rentalTotal"
                        :hideHeader="true"
                        :claim="claim"
                        :rentals="rentals"
                        :assessment-subtotal="assessment ? assessment.subtotal : 0"
                        :other-party="otherParty"
                        v-on="$listeners"
                ></block-claim-type>
            </tab>
            <tab name="Rental Req" id="rental-req">
                <block-other-party-rental-requirement :hideHeader="true"
                                                      :rental="rental"
                                                      :rentals="rentals"
                                                      :otherPartyId="otherParty.id"
                                                      :dateAsap="(otherParty.info && otherParty.info.completeApplication &&  otherParty.info.completeApplication[2] && otherParty.info.completeApplication[2].condition)?otherParty.info.completeApplication[2].additional:null">
                </block-other-party-rental-requirement>
            </tab>
            <tab v-if="otherParty.info && otherParty.info.reasonForHire" name="Reasons for Hire" id="reasons-for-hire">
                <block-other-party-reasons-for-hire v-if="otherParty.info && otherParty.info.reasonForHire" :hideHeader="true" :reasons="otherParty.info.reasonForHire">

                </block-other-party-reasons-for-hire>
            </tab>
            <tab v-else name="Reasons for Rental" id="reasons-for-rental">
                <block-reasons-for-rental v-if="!(otherParty.info && otherParty.info.reasonForHire)" :hideHeader="true" :reasons="otherParty.reasons">

                </block-reasons-for-rental>
            </tab>
            <tab name="Referrer" id="referrer">
                <block-referrer
                        :hideHeader="true"
                        :referrers="referrers"
                        :referrer="referrer"
                        :on-referrer-changed="onReferrerChanged"
                ></block-referrer>
            </tab>
            <tab name="Settlement" id="settlement">
              <block-settlement
                  :rental-total="rentalTotal || null"
                  :assessment-subtotal="assessment ? assessment.subtotal : 0"
                  :settled="settled"
              ></block-settlement>
            </tab>
            <tab name="Additionals" id="additionals">
              <block-additionals :additionals="otherParty.additionals" :additionals-to-delete="otherParty.additionalsToDelete"></block-additionals>
            </tab>
        </tabs>
    </div>
</template>
<script>
    /* eslint-disable */
    import BlockOtherPartyRentalRequirement from "../../../../components/block-other-party-rental-requirement";
    import BlockReasonsForRental from "../../../../components/block-reasons-for-rental";
    import BlockOtherPartyReasonsForHire from "../../../../components/block-other-party-reasons-for-hire";
    import BlockClaimType from "../../../../components/block-claim-type";
    import BlockReferrer from "@/components/block-referrer";
    import BlockSettlement from "../../../../components/block-settlement";
    import BlockAdditionals from "@/components/blocks/claim/additionals";
    export default {
        name: 'claim-view-other-party-rental-context-tab',
        props: {
            rentalTotal: [Number, null],
            claim: [Object, null],
            otherParty: Object,
            rental: {
                type: Object,
                default: function () {
                    return {
                        requirersRental: null,
                        asap: null,
                        agrmntNbr: '',
                        company: '',
                    };
                }
            },
            rentals: {
                type: Array,
                default: function () {
                    return [];
                }
            },
            assessment: [Object, null],
            'referrers': Array | null,
            'onReferrerChanged': Function,
            'referrer': Object,
            settled: {},
        },
        data: function () {
            return {};
        },
        computed: {},
        methods: {
            changedTab: function (obj) {
                this.$emit('change-active-rental-tab', obj.tab.hash);
            },
        },
        components: {
            BlockOtherPartyRentalRequirement,
            BlockReasonsForRental,
            BlockOtherPartyReasonsForHire,
            BlockClaimType,
            BlockReferrer,
            BlockSettlement,
            BlockAdditionals,
        },

    };
</script>

<style lang="scss" scoped>
    .other-party-rental-context-tab ::v-deep .tabs-component::after {
        /*height: 0px;*/
    }
    .sub-tabs-component.other-party-rental-context-tab ::v-deep .tabs-component {
        position: inherit;
    }
</style>
