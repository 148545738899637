<script>
    /* eslint-disable */
    // import VueSignaturePad from "vue-signature-pad";

    export default {
        name: 'block-claim-statement',
        props: {
            statement: {
                type: Object,
                default: function () {
                    return {
                        confirmation: null,
                        signature: null,
                    };
                }
            },
        },
        data: function () {
            return {
                signature: '',
                isOpenSignature: false,
                signatureBase64: null,
            };
        },
        methods: {
            resizeCanvas() {
                let vm = this;
                setTimeout(() => {
                    if (vm.$refs.signaturePad) {
                        vm.$refs.signaturePad.resizeCanvas();
                    }
                }, 100);
            },
            toDataURL(url, callback) {
                var httpRequest = new XMLHttpRequest();
                let vm = this;
                httpRequest.onload = function () {
                    var fileReader = new FileReader();
                    fileReader.onloadend = function () {
                        callback(fileReader.result);
                    };
                    fileReader.readAsDataURL(httpRequest.response);
                };
                httpRequest.open('GET', url);
                httpRequest.responseType = 'blob';
                httpRequest.send();
            },
            onBegin() {
            },
            onEnd() {
                const {isEmpty, data} = this.$refs.signaturePad.saveSignature();
                if (isEmpty) {
                    this.signature = null;
                } else {
                    this.signature = data;
                }
            },
            clearSignature() {
                this.$refs.signaturePad.clearSignature();
                this.onEnd();
            },
            openSignature() {
                let vm = this;
                this.isOpenSignature = true;
                this.$nextTick(() => {
                    vm.$refs.signaturePad.openSignaturePad();
                });
            },
            saveSignature() {
                this.isOpenSignature = false;
                const {isEmpty, data} = this.$refs.signaturePad.saveSignature();
                if (isEmpty || data == "data:,") {
                    this.signature = null;
                } else {
                    this.signature = data;
                }
                this.$nextTick(() => {
                    this.$refs.signaturePad.lockSignaturePad();
                    this.$emit('saveSignature', this.signature);
                });
            }
        },

        computed: {
            signatureImage() {
                if (this.statement.signature) {
                    return this.statement.signature;
                }
                return null;
            },
            imageBase64() {
                if (this.signatureImage) {
                    console.log('this.signatureImage', this.signatureImage);
                    let vm = this;
                    this.toDataURL(this.signatureImage, function (dataUrl) {
                        vm.signatureBase64 = dataUrl;
                        vm.$nextTick(() => {
                            vm.$refs.signaturePad.fromDataURL(vm.signatureBase64);
                            vm.$nextTick(() => {
                                vm.$refs.signaturePad.mergeImageAndSignature(vm.signatureBase64);

                            });
                        });

                    });
                    return vm.signatureBase64;
                }
                return null;
            }
        },
        mounted() {
            this.$nextTick(() => {
                console.log('statement.mounted');
                this.$refs.signaturePad.lockSignaturePad();
            });
        },
        components: {
            // VueSignaturePad,
        },

    };
</script>
<template>
    <div class="card">
        <div class="card-header bg-navyblue">
            <strong>Statement</strong>
        </div>
        <div class="card-block bg-white">
            <div class="form-group row">
                <label class="col-sm-5 col-5 col-form-label">Confirmation of statement received</label>
                <div class="col-sm-7 col-7">
                    <label class="form-check-inline radio">
                        <input v-model="statement.confirmation" class="form-check-input" type="radio" value="true" @click="(!!statement.confirmation)?statement.confirmation=null:null"
                               @change="statement.confirmation=true">
                        <span class="icon"><i class='bx bx-check'></i></span>
                        <span class="text">Yes</span>

                    </label>
                    <label class="form-check-inline radio">
                        <input v-model="statement.confirmation" class="form-check-input" type="radio" value="false" @click="(!statement.confirmation)?statement.confirmation=null:null"
                               @change="statement.confirmation=false">
                        <span class="icon"><i class='bx bx-check'></i></span>
                        <span class="text">No</span>
                    </label>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-12 col-12 col-form-label">Signature</label>
                <div class="col-sm-12 col-12">
                    <div style=" height:208px">
                        <VueSignaturePad
                                width="100%"
                                height="208px"
                                ref="signaturePad"
                                :options="{ penColor: '#1C1F39', onBegin, onEnd, resizeHandler:this.resizeCanvas}"
                                :customStyle="{border: '#EEEEEE 1px solid'}"
                                :images="[imageBase64]"
                        />
                    </div>
                    <div class="form-group row" style="margin-top:10px;">
                        <div v-if="!isOpenSignature" class="col-6" style="text-align: left;">
                            <button @click="openSignature" type="button" class="btn btn-primary" data-dismiss="modal" style="width:70px;">Sign</button>
                        </div>
                        <div v-if="isOpenSignature" class="col-6" style="text-align: left;">
                            <button @click="saveSignature" type="button" class="btn btn-primary" data-dismiss="modal" style="width:70px;">Save</button>
                        </div>
                        <div v-if="isOpenSignature" class="col-6" style="text-align: right;">
                            <button @click="clearSignature" type="button" class="btn btn-primary" data-dismiss="modal" style="width:70px;">Clear</button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="false" class="form-group row">
                <div class="col-sm-12 col-12">
                    SECOND <img class="col-sm-11 col-11" :src="imageBase64">
                </div>
            </div>
        </div>
    </div>

</template>

<style scoped>
    .V3 .new-card .card {
        margin-bottom: 0px;
    }

    .cards-edit-page .card-block {
        padding-right: 0;
    }

    .V3 body.V3 .multiselect__select {
        height: 35px;
        right: 0px;
        top: 0px;
    }

    .V3 .card-new-body .card-block {
        padding: 0px;
    }

    .V3 .col-form-label {
        padding: 8px 0;
    }

    @media (max-width: 575.98px) {
        .V3 .col-block .col-form-label {
            padding: 8px 15px;
        }
    }
</style>
